import TextField from "components/Form/TextField/TextField";
import TimePickerField from "components/Form/TimePickerField/TimePickerField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import { useSBSState } from "context/global";
import { fetchTransporters } from "services/lookups";
import Locale from "translations";
import { useEffect, useState } from 'react';
import validate from 'helpers/validate';
import SelectField from 'components/Form/SelectField/SelectField';
import { PlusIcon } from "modules/UmrahOperations/shared/Icons";
import { fetchPorts } from 'services/lookups';
import { initialdepartureData } from '../multiStepFormConfig';

export default function AirFields({ formState, setFormState }) {
  const { safaVisa, operationStatement, packages } = Locale;
  const { data, errors } = formState.arrival_departure;
  const [ports, setPorts] = useState([])
  const [halls, setHalls] = useState([]);
  console.log("data", data)


  function handleArrivalInputChange({ key, value, required }) {
    setFormState((prev) => ({
      ...prev,
      arrival_departure: {
        ...prev.arrival_departure,
        data: {
          ...prev.arrival_departure.data,
          [key]: value,
        },
        errors: {
          ...prev.arrival_departure.errors,
          ...validate({ name: key, value }, { required }),
        },
      },
    }));
  }

  function handleDepartureInputChange({ key, value, index, required }) {
    setFormState((prev) => {
      return ({
        ...prev,
        arrival_departure: {
          ...prev.arrival_departure,
          data: {
            ...prev.arrival_departure.data,
            deprature: prev.arrival_departure.data.deprature.map(
              (dep, depIndex) =>
                depIndex === index
                  ? {
                    ...dep,
                    data: {
                      ...dep.data,
                      [key]: value,
                    },
                    errors: {
                      ...dep.errors,
                      ...validate({ name: key, value }, { required }),
                    },
                  }
                  : dep
            ),
          },

        },
      })
    });
  }

  const addDeparture = () => {
    setFormState((prev) => ({
      ...prev,
      arrival_departure: {
        ...prev.arrival_departure,
        data: {
          ...prev.arrival_departure.data,
          deprature: [
            ...prev.arrival_departure.data.deprature,
            initialdepartureData,
          ],
        },
      },
    }));
  };

  // Todo: Move it to the parent component to avoid multiple calls to the API and duplicate code
  const portsFetch = async (transportationTypes) => {
    if (transportationTypes) {
      let Ports = await fetchPorts(966, transportationTypes === "Air" ? 2 : 1);
      console.log("Ports", Ports)
      setPorts(Ports);
      setHalls(Ports?.find(port => port.id === data.arrivalAirport)?.halls)
    }
  };

  useEffect(() => {
    portsFetch(data.transportType?.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/************************************************* Arrival Details *************************************************/}
      <div>
        <h3 className="title">{operationStatement.ArrivalDetails}</h3>

        <div className="inputs-wrapper">
          {/*  arrival Carrier Number */}
          <div>
            <CarrierName
              value={data.arrivalCarrierName?.name || data.arrivalCarrierName}
              onChange={(e) =>
                handleArrivalInputChange({
                  key: "arrivalCarrierName",
                  value: e,
                  required: true,
                })
              }
              onSelectValue={(e) =>
                handleArrivalInputChange({
                  key: "arrivalCarrierName",
                  value: e,
                  required: true,
                })
              }
              errors={errors.arrivalCarrierName}
              color={errors?.arrivalCarrierName?.required ? "danger" : ""}
            />
          </div>

          {/*  Trip Number*/}
          <div>
            <TextField
              label={operationStatement.tripNumber}
              placeholder={safaVisa.enterTripNumber}
              value={data.arrivalCarrierNumber}
              onChange={(e) =>
                handleArrivalInputChange({
                  key: "arrivalCarrierNumber",
                  value: e.target.value,
                  required: true,
                })
              }
              errors={errors.arrivalCarrierNumber}
              color={errors?.arrivalCarrierNumber?.required ? "danger" : ""}
            />
          </div>

          {/*  Arrival Date" */}
          <div>
            <DatePickerField
              label={operationStatement.ArrivalDate}
              date={data.arrivalDate}
              onChangeDate={(date) =>
                handleArrivalInputChange({
                  key: "arrivalDate",
                  value: date,
                  required: true,
                })
              }
              errors={errors.arrivalDate}
              color={errors?.arrivalDate?.required ? "danger" : ""}
            />
          </div>

          {/*  Arrival Time" */}
          <div>
            <TimePickerField
              label={packages.arrivalTime}
              placeholder={safaVisa.selectTime}
              date={data.arrivalTime}
              onChangeDate={(date) =>
                handleArrivalInputChange({
                  key: "arrivalTime",
                  value: date,
                  required: true,
                })
              }
              errors={errors.arrivalTime}
              color={errors?.arrivalTime?.required ? "danger" : ""}
            />
          </div>

          {/*  Arrival Airport" */}
          <div>
            <SelectField
              label={operationStatement.ArrivalAirport}
              placeholder={safaVisa.selectAirport}
              options={ports}
              value={data.arrivalAirport?.name || ports?.find(port => port.id === data.arrivalAirport)?.name || null}
              onChange={(e) => {
                handleArrivalInputChange({
                  key: "arrivalAirportHall",
                  value: null,
                  required: true,
                })
                handleArrivalInputChange({
                  key: "arrivalAirport",
                  value: e,
                  required: true,
                })

                setHalls(e.halls)
              }}
              errors={errors.arrivalAirport}
              color={errors?.arrivalAirport?.required ? "danger" : ""}
            />
          </div>

          {/*  Arrival Airport Hall" */}
          <div>
            <SelectField
              label={operationStatement.ArrivalAirportHall}
              placeholder={safaVisa.selectAirportHall}
              options={halls}
              value={data.arrivalAirportHall?.name || halls?.find(hall => hall.id === data.arrivalAirportHall)?.name || null}
              onChange={(e) =>
                handleArrivalInputChange({
                  key: "arrivalAirportHall",
                  value: e,
                  required: true,
                })
              }
              errors={errors.arrivalAirportHall}
              color={errors?.arrivalAirportHall?.required ? "danger" : ""}
            />
          </div>
        </div>
      </div>

      {/************************************************* Departure Details *************************************************/}
      <div>
        <h3 className="title">{operationStatement.DepartureDetails}</h3>

        <div className="deprature-list">
          {formState.arrival_departure.data.deprature.map(
            (departure, index) => <DepartureRow
              departure={departure}
              ports={ports}
              index={index}
              handleDepartureInputChange={handleDepartureInputChange}
            />
          )}
        </div>
      </div>

      <button
        type="button"
        onClick={addDeparture}
        className="add-departure-btn"
      >
        <span className="icon">
          <PlusIcon />
        </span>
        {operationStatement.AddDeparture}
      </button>
    </>
  )
}


const CarrierName = ({ value, onChange, onSelectValue, ...props }) => {
  const [transportersList, setTransportersList] = useState([]);
  const { locale } = useSBSState();
  const { operationStatement } = Locale;

  const getTransportersList = async (inputValue) => {
    if (inputValue.length > 2) {
      const transportersRes = await fetchTransporters(2, inputValue);
      let result = [];
      transportersRes?.forEach((item) => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        });
      });
      setTransportersList(result);
    }
  };

  return (
    <AutoCompleteField
      placeholder={operationStatement.CarrierName}
      label={operationStatement.CarrierName}
      isSearchable={true}
      listAuto={transportersList}
      setListAuto={setTransportersList}
      getListAuto={getTransportersList}
      value={value}
      id="transportersDropDown"
      onChange={onChange}
      onSelectValue={onSelectValue}
      {...props}
    />
  );
};


const DepartureRow = ({ departure, index, ports, handleDepartureInputChange }) => {
  const { safaVisa, operationStatement, packages } = Locale;
  const [halls, setHalls] = useState([]);

  useEffect(() => {
    if (departure.data?.departureAirport) {
      setHalls(ports?.find(p => p.id === departure.data?.departureAirport)?.halls || [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ports])

  return (
    <div key={index} className="inputs-wrapper">
      <div>
        <CarrierName
          value={
            departure.data?.departureCarrierName?.name ||
            departure.data?.departureCarrierName
          }
          onChange={e => handleDepartureInputChange({ key: "departureCarrierName", value: e, index, required: true })}
          onSelectValue={e => handleDepartureInputChange({ key: "departureCarrierName", value: e, index, required: true })}
          errors={departure.errors.departureCarrierName}
          color={
            departure.errors?.departureCarrierName?.required
              ? "danger"
              : ""
          }
        />
      </div>

      <div>
        <TextField
          label={operationStatement.tripNumber}
          placeholder={safaVisa.enterTripNumber}
          value={departure.data?.departureCarrierNumber}
          onChange={e => handleDepartureInputChange({ key: "departureCarrierNumber", value: e.target.value, index, required: true })}
          errors={departure.errors.departureCarrierNumber}
          color={
            departure.errors?.departureCarrierNumber?.required
              ? "danger"
              : ""
          }
        />
      </div>

      <div>
        <DatePickerField
          label={operationStatement.DepartureDate}
          date={departure.data?.departureDate}
          onDateChange={date => handleDepartureInputChange({ key: "departureDate", value: date, index, required: true })}
          errors={departure.errors.departureDate}
          color={
            departure.errors?.departureDate?.required ? "danger" : ""
          }
        />
      </div>

      <div>
        <TimePickerField
          label={safaVisa.departureTime}
          placeholder={safaVisa.selectTime}
          date={departure.data?.departureTime}
          onChangeDate={date => handleDepartureInputChange({ key: "departureTime", value: date, index, required: true })}
          errors={departure.errors.departureTime}
          color={
            departure.errors?.departureTime?.required ? "danger" : ""
          }
        />
      </div>

      <div>
        <SelectField
          label={operationStatement.DepartureAirport}
          placeholder={safaVisa.selectAirport}
          options={ports}
          value={departure.data?.departureAirport?.name || ports?.find(port => port.id === departure.data?.departureAirport)?.name || null}
          onChange={e => {
            handleDepartureInputChange({ key: "departureAirportHall", value: null, index, required: true })
            handleDepartureInputChange({ key: "departureAirport", value: e, index, required: true })
            setHalls(e.halls)
          }}
          errors={departure.errors.departureAirport}
          color={
            departure.errors?.departureAirport?.required
              ? "danger"
              : ""
          }
        />
      </div>

      <div>
        <SelectField
          label={operationStatement.DepartureAirportHall}
          placeholder={safaVisa.selectAirportHall}
          options={halls}
          value={departure.data?.departureAirportHall?.name || halls?.find(hall => hall.id === departure.data?.departureAirportHall)?.name || null}
          onChange={e => handleDepartureInputChange({ key: "departureAirportHall", value: e, index, required: true })}
          errors={departure.errors.departureAirportHall}
          color={
            departure.errors?.departureAirportHall?.required
              ? "danger"
              : ""
          }
        />
      </div>
    </div>
  )
}