import { useWebBuilderState } from 'context/webBuilder';
import React from 'react'
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import Locale from 'translations';


export default function FlightServiceBuilderCard(props) {
  const { selectedLanguage } = useWebBuilderState();
  const { webBuilder } = Locale;

  const { product_uuid, image, name, name_localized, destination, price, currency } = props;


  return (
    <article className="flight-service-builder-card">
      <div className="card-image">
        <img src={image} alt={name_localized ? name_localized[selectedLanguage] : name} />
      </div>
      <div className="card-details">
        <h5 className="title">
          {name_localized ? name_localized[selectedLanguage] : name}
        </h5>
        <p className="location mt-2">
          <LocationIcon />
          <span className="mx-2">
            {destination[selectedLanguage]}
          </span>
        </p>

        <p className="price">
          <span>{webBuilder.from}</span>
          <span className="amount mx-1">{price}</span>
          <span className="currency">{currency}</span>
        </p>
      </div>

    </article>
  )
}
