import { useEffect, useRef, useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Servicies
import { fetchCountryPortsWithSearch } from 'services/serviveBuilder';
// Helper
import validate from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Custom Hook
import useSharedData from '../constant/useSharedData';
import useDidMountEffect from 'hooks/useDidMountEffect ';
import useDebounce from 'hooks/useDebounce';
// ---------------------------------------------------------------------------

const MiniFlightForm = ({ data, setData, packageType, isSubmit }) => {
  // *** hooks
  const { Service_Builder, inventory, commons } = Locale

  const { status } = useParams()

  const { allCountries } = useSBSState()

  const { requiredFlightInputs } = useSharedData()

  let goingToPrevValue = useRef(null);

  const isUmrah = packageType === 'Hajj' || packageType === 'Umrah' || packageType === 'Umrah plus'

  // ** states
  const [flightData, setFlightData] = useState(data);

  const [destenationOnSearch, setDestenationOnSearch] = useState({ children: 0 });

  const [errors, setErrors] = useState({});

  const [flights, setFlights] = useState({ children: 0 });
  const [listAuto, setListAuto] = useState([]);
  const [listAutoTo, setListAutoTO] = useState([]);

  // *** logic functions
  function handleFlightInputsChanges({ key, value }) {
    let flightDataClone = { ...flightData }
    flightDataClone[`${key}`] = value;
    setFlightData({ ...flightDataClone });
  }

  function checkFormErrors() {
    let submitError = {};
    requiredFlightInputs?.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: flightData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  // AutoCompleteField port from
  const getListAuto = async (inputValue) => {
    if (inputValue.length > 2) {
      const Airlines = await fetchCountryPortsWithSearch(null, inputValue);;

      let result = [];
      Airlines.map((item) => {
        result.push({
          name: item.name,
          id: item.id,
          value: item.id,
          label: item.name,
        });
      });
      setListAuto(result);
    }
  };

  // AutoCompleteField port to 
  const getListAutoTO = async (inputValue) => {
    const countryId = isUmrah ? 966 : flightData?.destination?.country_id || flightData?.destination?.id
    if (inputValue.length > 2) {
      const Airlines = await fetchCountryPortsWithSearch(countryId, inputValue);
      let result = [];
      Airlines.map((item) => {
        result.push({
          name: item.name,
          id: item.id,
          value: item.id,
          label: item.name,
        });
      });
      setListAutoTO(result);
    }
  };

  const debouncedGetListAuto = useDebounce((searchText) => getListAuto(searchText), 600);
  const debouncedGetListAutoTo = useDebounce((searchText) => getListAutoTO(searchText), 600);

  function clearSearchText(e, key) {
    goingToPrevValue.current = destenationOnSearch[key];
    const value = e.target.value;
    if (value.length > 0) {
      setDestenationOnSearch({ ...destenationOnSearch, [key]: null });
    }
  }

  useDidMountEffect(() => {
    if (isSubmit) {
      checkFormErrors();
    }
    setData(flightData)
  }, [flightData, isSubmit])

  useEffect(() => {
    if (isUmrah) {
      setFlightData({ ...data, destination: { id: 966, name: 'Saudi Arabia' } })
      setFlights({ to: { name: '' } })
    } else {
      // setFlightData({ ...data, destination: '', to_port: null })
      // setFlights({ to: { name: '' } })
    }
  }, [isUmrah])


  // --------- JSX Code --------
  return (
    <form>
      {/* Flight Service Form  */}
      <div className='row form-holder mx-0 border-0 align-items-start'>

        {/* Service Name en */}
        <div className="col-4 custom-field mt-2">
          <TextField
            type="text"
            label={Service_Builder?.service_name_en}
            hasLabel={status === 'edit' ? true : false}
            placeholder={Service_Builder?.service_name_en}
            name="name_en"
            id="name_en"
            value={flightData?.name_en}
            onChange={(e) => {
              handleFlightInputsChanges({ key: 'name_en', value: e?.target?.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "name_en", value: e?.target?.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.name_en?.required ? "danger" : ""}
            errors={errors?.name_en}
          />
        </div>

        {/* Service Name ar */}
        <div className="col-4 custom-field mt-2">
          <TextField
            type="text"
            label={Service_Builder?.service_name_ar}
            hasLabel={status === 'edit' ? true : false}
            placeholder={Service_Builder?.service_name_ar}
            name="name_ar"
            id="name_ar"
            value={flightData?.name_ar}
            onChange={(e) => {
              handleFlightInputsChanges({ key: 'name_ar', value: e?.target?.value })
              setErrors({
                ...errors,
                ...validate(
                  { name: "name_ar", value: e?.target?.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.name_ar?.required ? "danger" : ""}
            errors={errors?.name_ar}
          />
        </div>

        <div className="col-4 mt-2" />

        {/* Destenation */}
        <div className="col-4 mt-2">
          <SelectField
            disabled={isUmrah}
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.destination}
            placeholder={Service_Builder?.destination}
            id="destination"
            name="destination"
            value={flightData?.destination?.name}
            options={allCountries}
            onChange={(e) => {
              handleFlightInputsChanges({ key: 'destination', value: e })
              setErrors({
                ...errors,
                ...validate(
                  { name: "destination", value: e },
                  { required: true }
                ),
              });
            }}
            color={errors?.destination?.required ? "danger" : ""}
            errors={errors?.destination}
          />
        </div>

        {/* From Airport (Optional) */}
        <div className="col-4 custom-field mt-2">
          <div className="form-group">
            <AutoCompleteField
              disabled={!flightData?.destination}
              hasLabel={status === 'edit' ? true : false}
              labelInner={false}
              isImage={false}
              // image={fromIcom}
              label={Service_Builder.from_airPort_optional}
              isSearchable={true}
              placeholder={Service_Builder.from_airPort_optional}
              listAuto={listAuto}
              setListAuto={setListAuto}
              getListAuto={debouncedGetListAuto}
              value={flightData?.from_port?.name}
              // flag={flights.from?.country?.flag}
              onFocus={(e) => clearSearchText(e, "from")}
              // onBlur={() =>
              //   // setFlights({
              //   //   ...flights,
              //   //   from: goingToPrevValue.current,
              //   // })
              // }
              onChange={(e) => {
                // setFlights({
                //   ...flights,
                //   from: { name: e },
                // });
                setFlightData({ ...flightData, from_port: { name: e } })
              }}
              onSelectValue={(selectedObj) => {
                // setFlights({
                //   ...flights,
                //   from: selectedObj,
                // });
                handleFlightInputsChanges({ key: 'from_port', value: selectedObj });
                // setErrors({
                //   ...errors,
                //   ...validate({ name: "from_port", value: selectedObj }, { required: false }),
                // });
              }}
            // color={errors?.from_port?.required ? "danger" : ""}
            // errors={errors?.from_port}
            />
            <i class="fas fa-exchange-alt d-none"></i>
          </div>
        </div>

        {/* To AirPort (Optional )*/}
        {/* <div className="col-4 custom-field mt-2">
          <div className="form-group">
            <AutoCompleteField
              disabled={!flightData?.destination}
              hasLabel={status === 'edit' ? true : false}
              labelInner={false}
              label={Service_Builder?.to_airPort_optional}
              isSearchable={true}
              isImage={false}
              // image={toIcon}
              placeholder={Service_Builder?.to_airPort_optional}
              listAuto={listAutoTo}
              setListAuto={setListAutoTO}
              getListAuto={getListAutoTO}
              value={flightData?.to_port?.name}
              // flag={flights.to?.country?.flag}
              onFocus={(e) => clearSearchText(e, "to")}
              // onBlur={() =>
              //   // setFlights({
              //   //   ...flights,
              //   //   to: goingToPrevValue.current,
              //   // })
              // }
              onChange={(e) => {
                setFlightData({ ...flightData, to_port: { name: e } })
              }}
              onSelectValue={(selectedObj) => {
                // setFlights({
                //   ...flights,
                //   to: selectedObj,
                // });
                handleFlightInputsChanges({ key: 'to_port', value: selectedObj });
                // setErrors({
                //   ...errors,
                //   ...validate({ name: "to_port", value: selectedObj }, { required: false }),
                // });
              }}
            // color={errors?.to_port?.required ? "danger" : ""}
            // errors={errors?.to_port}
            />
            <i class="fas fa-exchange-alt d-none"></i>
          </div>
        </div> */}
        <span />
        <div className="col-4 custom-field mt-2">
          <div className="form-group">
            <AutoCompleteField
              disabled={!flightData?.destination}
              hasLabel={status === 'edit' ? true : false}
              labelInner={false}
              label={Service_Builder?.to_airPort_optional}
              isSearchable={true}
              isImage={false}
              // image={toIcon}
              placeholder={Service_Builder?.to_airPort_optional}
              listAuto={listAutoTo}
              setListAuto={setListAutoTO}
              getListAuto={debouncedGetListAutoTo}
              value={flightData?.to_port?.name}
              // flag={flights.to?.country?.flag}
              onFocus={(e) => clearSearchText(e, "to")}
              // onBlur={() =>
              //   setFlights({
              //     ...flights,
              //     to: goingToPrevValue.current,
              //   })
              // }
              onChange={(e) => {
                // setFlights({
                //   ...flights,
                //   to: { name: e },
                // });
                setFlightData({ ...flightData, to_port: { name: e } })
              }}
              onSelectValue={(selectedObj) => {
                // setFlights({
                //   ...flights,
                //   to: selectedObj,
                // });
                handleFlightInputsChanges({ key: 'to_port', value: selectedObj });
                setErrors({
                  ...errors,
                  ...validate({ name: "to_port", value: selectedObj }, { required: false }),
                });
              }}
            // color={errors?.to_port?.required ? "danger" : ""}
            // errors={errors?.to_port}
            />
          </div>
        </div>


        {/* Flight Description*/}
        <div className="col-12 custom-field mt-2">
          <TextAreaField
            hasLabel={status === 'edit' ? true : false}
            label={Service_Builder?.description}
            height={'126px'}
            placeholder={Service_Builder?.description}
            value={flightData?.description_en}
            onChange={(e) => {
              handleFlightInputsChanges({ key: 'description_en', value: e?.target?.value })
              setErrors({
                ...errors,
                ...validate(
                  { name: "description_en", value: e?.target?.value },
                  { required: true }
                ),
              });
            }
            }
            color={errors?.description_en?.required ? "danger" : ""}
            errors={errors?.description_en}
          />
        </div>

        {/* Flight Iamges*/}
        <div className="col-12 mt-2">
          <div className='images-holder d-flex align-items-center '>
            <UploadFile
              id={`upload-hotel-image-${generateUniqueID()}`}
              label={Service_Builder?.add_images}
              value={flightData.images}
              onChange={(images) => {
                handleFlightInputsChanges({ key: "images", value: images })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "images", value: images.length, },
                    { required: true }
                  ),
                });
              }}
              errorMsg={
                errors?.images?.required
                  ? inventory.viewReservation.Photos + " " + commons.isRequired
                  : false
              }
            />
          </div>
        </div>

      </div>
    </form>
  )
}

export default MiniFlightForm