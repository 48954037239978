import React, { useEffect, useState } from 'react'
import BreadCrump from '../../shared/BreadCrump'
import SettingsDepartmentDetails from './DepartmentDetails'
import SettingsHeaderFooter from './HeaderFooter'
import SerialBankInfo from './SerialBankInfo';
import { useSBSState } from 'context/global';
import { ReactComponent as SaveIcon } from "assets/images/umrah-operations/save-icon.svg";
import Locale from 'translations';
// import validate, { isFormValid } from "helpers/validate";
import { addBasicSettings, getBasicSettings, setNusukCredentials, updateBasicSettings } from 'services/operationStatement';
import { uploadFile } from 'services/auth';
import { formatBaiscSettings } from './formatBaiscSettings';
import { store } from 'react-notifications-component';
import ShowForPermission from 'helpers/showForPermission';
import ShowSubscription from 'helpers/ShowSubscription';
import TextField from 'components/Form/TextField/TextField';
import PasswordField from 'components/Form/PasswordField/PasswordField';
import validate, { isFormValid } from 'helpers/validate';



export default function BasicSettings() {
  const { marketPlace, commons, operationStatement, login } = Locale;
  const { allCountries } = useSBSState();
  const saudiFlagUUID = "f4e1fb09-cc9e-473a-828f-02fb764d8394";

  const initalPhoneCode = {
    value: "+966",
    label: "+966",
    phone_code: "+966",
    flag: saudiFlagUUID,
  };


  const headerImageSettingsLookup = [
    { name: operationStatement.banner, value: "banner", id: "banner" },
    { name: operationStatement.center, value: "center", id: "center" },
    { name: operationStatement.left, value: "left", id: "left" },
    { name: operationStatement.right, value: "right", id: "right" },
  ];

  const [basicSettingsData, setBasicSettingsData] = useState({
    operating_serial_code: "",
    operating_serial_start: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_swift_number: "",
    operation_management_name: "",
    operation_management_phone_code: initalPhoneCode,
    operation_management_phone: "",
    operation_management_whats_app_phone_code: initalPhoneCode,
    operation_management_whats_app_phone: "",
    operation_management_email: "",
    reservation_management_name: "",
    reservation_management_phone_code: initalPhoneCode,
    reservation_management_phone: "",
    reservation_management_whats_app_phone_code: initalPhoneCode,
    reservation_management_whats_app_phone: "",
    reservation_management_email: "",
    account_management_name: "",
    account_management_phone_code: initalPhoneCode,
    account_management_phone: "",
    account_management_whats_app_phone_code: initalPhoneCode,
    account_management_whats_app_phone: "",
    account_management_email: "",
    header_image: null,
    header_image_preview: null,
    header_image_setting: headerImageSettingsLookup[0],
    footer_details: "",
    licence_key: ""
  });
  const [nusukState, setNusukState] = useState({
    username: "",
    password: "",
    code: ""
  });

  const [activeTab, setActiveTab] = useState("basic");

  const [errors, setErrors] = useState({});
  // const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  // const validationKeys = ["operating_serial_code", "operating_serial_start"];


  function handleInputChanges(keyName, value) {
    setBasicSettingsData({
      ...basicSettingsData,
      [keyName]: value
    });

    // if (validationKeys.includes(keyName)) {
    //   setErrors({
    //     ...errors,
    //     ...validate(
    //       { name: keyName, value },
    //       { required: true }
    //     ),
    //   });
    // }
  }

  // function checkFormErrors() {
  //   let submitErrors = {};
  //   validationKeys.forEach((key) => {
  //     submitErrors = {
  //       ...submitErrors,
  //       ...validate(
  //         { name: key, value: basicSettingsData[key] },
  //         { required: true }
  //       ),
  //     };
  //   });
  //   setErrors({ ...submitErrors });
  // }

  // function submit() {
  //   checkFormErrors();
  //   if (!isErrorLoaded) {
  //     setIsErrorLoaded(true);
  //   } else {
  //     setIsErrorLoaded(false);
  //   }
  // }

  // upload header image
  async function uploadHeaderImage() {
    if (basicSettingsData.header_image && typeof basicSettingsData.header_image !== "string") {
      const formData = new FormData();
      formData.append("file", basicSettingsData.header_image);
      formData.append("name", basicSettingsData.header_image?.name?.toLowerCase());
      formData.append("bucket", "basicSettings");
      const imageRes = await uploadFile(formData);
      if (imageRes.data.data.uuid) {
        return imageRes.data.data.uuid;
      } else {
        store.addNotification({
          message: JSON.parse(imageRes?.data?.message?.replace(/\\/, " ")).message || commons.somethingWentWrong,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
    }
    return basicSettingsData.header_image
  }

  // add or update baisc settings
  async function saveUpdateBasicSettings() {
    const headerImageUUID = await uploadHeaderImage();
    const data = {
      ...basicSettingsData,
      operating_serial_start: +basicSettingsData?.operating_serial_start,
      account_management_phone_code: basicSettingsData?.account_management_phone_code?.phone_code,
      account_management_whats_app_phone_code: basicSettingsData?.account_management_whats_app_phone_code?.phone_code,
      header_image: headerImageUUID,
      header_image_setting: basicSettingsData?.header_image_setting?.value,
      operation_management_phone_code: basicSettingsData?.operation_management_phone_code?.phone_code,
      operation_management_whats_app_phone_code: basicSettingsData?.operation_management_whats_app_phone_code?.phone_code,
      reservation_management_phone_code: basicSettingsData?.reservation_management_phone_code?.phone_code,
      reservation_management_whats_app_phone_code: basicSettingsData?.reservation_management_whats_app_phone_code?.phone_code,
    }

    // if (headerImageUUID) {
    const res = basicSettingsData?.id ? await updateBasicSettings(basicSettingsData?.id, data) : await addBasicSettings(data);
    if (res?.status >= 200 && res?.status < 300) {
      const basicSettingsFormat = formatBaiscSettings(res?.data?.data, allCountries, headerImageSettingsLookup, initalPhoneCode);
      setBasicSettingsData(basicSettingsFormat);
      store.addNotification({
        message: "basic settings updated successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    // }
  }
  // add or update baisc settings

  function checkNusukFormErrors() {
    let submitError = {};
    Object.keys(nusukState).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: nusukState[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  function handleInputsChanges({ key, value }) {
    setNusukState({
      ...nusukState,
      [key]: value
    })
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
  }

  async function handleNusukSubmit(e) {
    e.preventDefault();
    const formHasErrors = !isFormValid(checkNusukFormErrors());
    if (formHasErrors) return;
    const res = await setNusukCredentials(nusukState);
    if (res?.status === 200) {
      store.addNotification({
        message: "basic settings updated successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  // get baisc setting data
  useEffect(() => {
    async function getBasicSettingsData() {
      const res = await getBasicSettings();
      if (res?.status >= 200 && res?.status < 300) {
        const basicSettingsFormat = formatBaiscSettings(res?.data?.data, allCountries, headerImageSettingsLookup, initalPhoneCode);
        setBasicSettingsData(basicSettingsFormat);
        setNusukState({
          username: res?.data?.data?.nusuk_username,
          password: res?.data?.data?.nusuk_password,
          code: res?.data?.data?.nusuk_code,
        })
      }
    }
    if (allCountries && allCountries?.length > 0) {
      getBasicSettingsData()
    }
  }, [allCountries])


  return (
    <ShowSubscription module="operation_setting">
      <div className="operations-settings px-new-26">
        <BreadCrump title={operationStatement.basicSettings}></BreadCrump>

        <div className='tabs mt-0 justify-content-center'>
          <button className={`btn shadow-none movement-manage  ${activeTab === 'basic' ? 'selected' : ''}`}
            onClick={() => setActiveTab("basic")}
          >
            {/* <UserTagIcon /> */}
            {Locale.operationStatement.basicSettings}
          </button>
          <button className={`btn shadow-none movement-group ${activeTab === 'nusuk' ? 'selected' : ''}`}
            onClick={() => setActiveTab("nusuk")}
          >
            {/* <UsersGroupIcon /> */}
            {Locale.operationStatement.nusukSettings}
          </button>
        </div>

        {activeTab === "basic" &&
          <>
            {/* serial and bank informations */}
            <SerialBankInfo
              basicSettingsData={basicSettingsData}
              errors={{}}
              handleInputChanges={handleInputChanges}
            />

            {/* Department details */}
            <SettingsDepartmentDetails
              countriesLookup={allCountries}
              basicSettingsData={basicSettingsData}
              handleInputChanges={handleInputChanges}
            />

            {/* Header & Footer */}
            <SettingsHeaderFooter
              headerImageSettingsLookup={headerImageSettingsLookup}
              basicSettingsData={basicSettingsData}
              setBasicSettingsData={setBasicSettingsData}
            />

            <div className="d-flex justify-content-end pb-3">
              <ShowForPermission permission="Manage-operation-statement">
                <button className='btn bg-nxt save-btn' onClick={saveUpdateBasicSettings}>
                  <SaveIcon />
                  <span className='mx-1'>{marketPlace.SaveChanges}</span>
                </button>
              </ShowForPermission>
            </div>
          </>
        }

        {activeTab === "nusuk" &&
          <form onSubmit={handleNusukSubmit} autoComplete="off" className="w-100 pb-3">
            <div className="row mx-0">
              {/* username */}
              <div className="col-md-4 col-12 mt-3">
                <TextField
                  type="text"
                  label={"Username"}
                  placeholder={"Username"}
                  name="nusuk_username"
                  id="nusuk_username"
                  value={nusukState.username}
                  onChange={(e) => handleInputsChanges({ key: "username", value: e.target.value })}
                  color={errors?.username?.required ? "danger" : ""}
                  errors={errors?.username}
                />
              </div>

              {/* password */}
              <div className="col-md-4 col-12 mt-3">
                <PasswordField
                  hasLabel={true}
                  label={login.password}
                  placeholder={login.passwordPlaceholder}
                  name="nusuk_password"
                  id="nusuk_password"
                  value={nusukState.password}
                  onChange={(e) => handleInputsChanges({ key: "password", value: e.target.value })}
                  color={errors?.password?.required || errors?.password?.password ? "danger" : ""}
                  errors={errors?.password}
                />
              </div>

              {/* code */}
              <div className="col-md-4 col-12 mt-3">
                <TextField
                  label={"Code"}
                  placeholder={"Code"}
                  name="nusuk_code"
                  id="nusuk_code"
                  value={nusukState.code}
                  onChange={(e) => handleInputsChanges({ key: "code", value: e.target.value })}
                  color={errors?.code?.required ? "danger" : ""}
                  errors={errors?.code}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end w-100">
              <button className='btn bg-nxt save-btn mt-3'>
                <SaveIcon />
                <span className='mx-1'>{marketPlace.SaveChanges}</span>
              </button>
            </div>
          </form>
        }
      </div>
    </ShowSubscription>
  )
}
