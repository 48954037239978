import React, { useEffect, useState } from 'react'
// Translation
import Locale from 'translations';
// Componetnts
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import NumberField from 'components/Form/NumberField/NumberField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import UploadInput from 'components/UploadInput';
// Context
import { useSBSState } from 'context/global';
// ReactStarp
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
// Assets
import { SaveIcon } from 'modules/UmrahOperations/shared/Icons';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Moments
import moment from 'moment';
import { handleResponesNotification } from '../helper/accountingHelpers';
// Services
import {
  createGuideRequest,
  fetchDelegateCompanies,
  fetchGroupsLookup,
  fetchOfficersLookup,
  updateGuideRequest
} from 'services/operationStatement';
import { fetchCities, fetchCityPorts } from 'services/lookups';
// ------------------------------------------------------------------------------------

const requiredKeys = ["location", "description", 'city', 'airport', 'hall'];
const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function AddEditRequestModal(props) {

  const {
    isOpen,
    guideData,
    toggle,
    setGuideModal,
    setReload,
    officerData,
  } = props

  // ** hooks
  const {
    operationStatement,
    guideRequests,
    Service_Builder,
    productElements,
    movementManagement,
    commons
  } = Locale;

  const { allCountries, companyInfo } = useSBSState();


  const isServiceCompany = companyInfo?.company_category === "services_company";

  // ** states
  const [errors, setErrors] = useState({});
  const [cities, setCities] = useState([]);
  const [ports, setPorts] = useState([]);
  const [halls, setHalls] = useState([]);

  const [officialData, setOfficialData] = useState({
    // attach_type: officerData?.group || isServiceCompany ? "officer" : officerData?.company ? "company" : null,
    // company: null,
    // officer: {
    //   id: officerData?.id || null,
    //   name: officerData?.name || null,
    //   phone_code: officerData?.phone_country || null,
    //   phone_number: officerData?.phone || null,
    //   email: officerData?.email || null,
    // },
    // officer_id: officerData?.id,
    // group: officerData?.group,
    // attach_file: null
  });

  const [lookups, setLookups] = useState({
    groups: null,
    companies: null,
    officers: [],
  });

  const showAttachFileInput = officialData.attach_type && (officerData?.movmentType === "departure" || officerData?.movmentType === "arrival")

  // ** functions

  // check if guide request form has errors
  function checkFormErrors() {
    let submitError = {};
    Object.keys(guideData.data).forEach((key) => {
      if (requiredKeys.includes(key)) {
        submitError = {
          ...submitError,
          ...validate(
            { name: key, value: guideData.data[key] },
            { required: true }
          ),
        };
      }
    });
    setErrors(() => submitError);
    return submitError;
  }

  // guide request modal input changes
  function handleGuideInputsChanges({ key, value }) {
    let guideDataClone = { ...guideData.data }
    guideDataClone = { ...guideDataClone, [key]: value };
    if (key === 'city') {
      // fetchPorts(value?.id)
      fetchPorts(966)
    }
    if (key === 'airport') {
      // console.log(value)
      setHalls(value?.halls)
    }
    if (requiredKeys.includes(key)) {
      setErrors({
        ...errors,
        ...validate(
          { name: key, value },
          { required: true, email: key === "email" }
        ),
      });
    }
    setGuideModal({ ...guideData, data: guideDataClone })
  }

  // ** handle upload file **
  async function handleUploadFile(file, fileId) {
    setOfficialData({
      ...officialData,
      attach_file: fileId
    });
    setErrors({
      ...errors,
      ...validate(
        { name: "attach_file", value: fileId },
        { required: true }
      ),
    });
  }

  const fetchCity = async () => {
    const citiesList = await fetchCities(966);
    setCities(citiesList);
  };

  const fetchPorts = async (id) => {
    const portsList = await fetchCityPorts(id)
    setPorts(portsList)
  }

  // handle create and update guide request 
  async function handleCreateUpdateGroup(requestId) {
    let formErrors = checkFormErrors();
    let res;
    if (isFormValid(formErrors)) {
      let message;
      let data = {
        address: guideData.data?.location,
        date: guideData.data?.date ? moment(guideData.data?.date).format("YYYY-MM-DD") : null,
        passport_number: guideData.data?.passport || null,
        phone_code: guideData.data?.phone_code?.phone_code || guideData.data?.phone_code?.value?.toString() || null,
        phone_number: guideData.data?.phone || null,
        description: guideData.data?.description,
        city_id: guideData?.data?.city?.id,
        port_id: guideData?.data?.airport?.id,
        hall_id: guideData?.data?.hall?.id,
        // officer_id: officialData?.attach_type === 'officer' ? officialData?.officer?.id : null,
        group_id: officialData?.attach_type === 'officer' ? officialData?.group?.id : null,
        attach_type: officialData?.attach_type, //company,
        company_id: officialData?.attach_type === 'company' ? officialData?.company?.id : null
      }
      res = requestId ? await updateGuideRequest(requestId, data) : await createGuideRequest(data);
      if (res?.status === 200 || res?.status === 201) {
        message = requestId ? "Request Updated Succesfully" : "Request Added Succesfully";
        handleResponesNotification({ alertType: "success", message, title: "" });

        toggle({ data: null, type: null });
        setReload(prev => !prev)
      }
    }
  }

  async function getOfficersFromGroup({ groupId }) {
    const res = await fetchOfficersLookup(groupId);
    if (res?.status === 200) {
      const officers = res?.data?.data?.map(officer => ({
        id: officer?.id,
        name: officer?.name,
        phone_code: officer?.phone_country,
        phone_number: officer?.phone,
        email: officer?.email,
      }))
      setLookups({ ...lookups, officers });
    }
  }

  async function getGroupsLookup() {
    const res = await fetchGroupsLookup();
    if (res?.status === 200) {
      setLookups({ ...lookups, groups: res?.data });
    }
  };

  async function getCompniesLookup() {
    const res = await fetchDelegateCompanies("delegation_management");
    if (res?.status === 200) {
      setLookups({ ...lookups, companies: res?.data?.data });
      setOfficialData({
        ...officialData,
        company: res?.data?.data?.find(company => company?.name === guideData.data?.request?.delegation_company_name)
      })
    }
  };

  useEffect(() => {
    if (guideData.data.phone_code && typeof guideData.data.phone_code === "string") {
      let phoneCode = allCountries?.find(country => country?.phone_code === guideData.data.phone_code)
      handleGuideInputsChanges({ key: "phone_code", value: phoneCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCountries, guideData.data.phone_code])


  useEffect(() => {
    // get groups in case officer and there is no groups lookup
    if (!lookups.groups && officialData.attach_type === "officer") {
      getGroupsLookup();
    }
    // get companies in case company and there is no companies lookup
    if ((!lookups.companies && officialData.attach_type === "company")) {
      getCompniesLookup()
    }

  }, [lookups, officialData.attach_type]);


  useEffect(() => {
    if (guideData.data?.request?.delegation_company_name && !isServiceCompany) {
      setOfficialData({
        ...officialData,
        attach_type: 'company',
        company: lookups.companies?.find(company => company?.name === guideData.data?.request?.delegation_company_name)
      })
    }
    if ((!guideData.data?.request?.delegation_company_name && guideData.data?.request?.officer) || isServiceCompany) {
      setOfficialData({
        ...officialData,
        attach_type: 'officer',
        officer: {
          id: guideData.data?.request?.officer?.id || null,
          name: guideData.data?.request?.officer?.name || null,
          phone_code: guideData.data?.request?.officer?.phone_country || null,
          phone_number: guideData.data?.request?.officer?.phone || null,
          email: guideData.data?.request?.officer?.email || null,
        },
        group: guideData.data?.request?.officer?.group
      })
      if (guideData?.type === 'Edit') {
        getOfficersFromGroup({ groupId: guideData.data?.request?.officer?.group?.id });
      }
    }
  }, [])

  useEffect(() => {
    fetchCity()
  }, [])


  console.log(guideData.data?.request?.delegation_company_name);
  console.log(lookups);
  console.log(officialData);
  console.log(guideData);



  // ------ JSX Code -------
  return (
    <Modal isOpen={isOpen} size='lg' className="operation-modal">

      <ModalHeader toggle={toggle}>
        {guideData.type === "Add" ? guideRequests.addNewRequest : guideRequests.editRequest}
      </ModalHeader>

      <ModalBody>
        <div className="row mx-0">
          {/* Location */}
          <div className="col-md-6 col-12">
            <TextField
              label={Locale.guideRequests.location}
              placeholder={Locale.guideRequests.typeLocation}
              id={"guide-location"}
              name={"guide-location"}
              value={guideData?.data?.location}
              onChange={(e) => handleGuideInputsChanges({ key: "location", value: e.target.value })}
              color={errors?.location?.required ? "danger" : ""}
              errors={errors?.location}
            />
          </div>

          {/* city */}
          <div className="col-md-6 col-12 ">
            <SelectField
              label={Service_Builder?.city}
              hasLabel={true}
              id="guide-city"
              name="guide-city"
              // placeholder={Locale.guideRequests.choose}
              value={guideData?.data?.city?.name}
              onChange={(e) => handleGuideInputsChanges({ key: "city", value: e })}
              options={cities}
              color={errors?.city?.required ? "danger" : ""}
              errors={errors?.city}
            />
          </div>

          {/* airport */}
          <div className="col-md-6 col-12 ">
            <SelectField
              disabled={!ports.length}
              label={operationStatement?.airport}
              hasLabel={true}
              id="guide-airport"
              name="guide-airport"
              placeholder={Locale.guideRequests.choose}
              value={guideData?.data?.airport?.name}
              onChange={(e) => handleGuideInputsChanges({ key: "airport", value: e })}
              options={ports}
              color={errors?.airport?.required ? "danger" : ""}
              errors={errors?.airport}
            />
          </div>

          {/* hall */}
          <div className="col-md-6 col-12 ">
            <SelectField
              disabled={!ports.length}
              label={productElements?.hall}
              hasLabel={true}
              id="guide-hall"
              name="guide-hall"
              placeholder={Locale.guideRequests.choose}
              value={guideData?.data?.hall?.name}
              onChange={(e) => handleGuideInputsChanges({ key: "hall", value: e })}
              options={halls}
              color={errors?.hall?.required ? "danger" : ""}
              errors={errors?.hall}
            />
          </div>

          {/* Request Date */}
          <div className="col-md-6 col-12">
            <DatePickerField
              label={Locale.guideRequests.requestDate}
              placeholder={Locale.guideRequests.date}
              id={"guide-date"}
              name={"guide-date"}
              date={guideData.data?.date}
              onChangeDate={(date) => handleGuideInputsChanges({ key: "date", value: date })}
            />
          </div>

          {/* Passport No. */}
          <div className="col-md-6 col-12">
            <TextField
              label={Locale.guideRequests.passport}
              placeholder={Locale.guideRequests.typePassportNo}
              id={"guide-passport"}
              name={"guide-passport"}
              value={guideData.data?.passport}
              onChange={(e) => handleGuideInputsChanges({ key: "passport", value: e.target.value })}
            />
          </div>

          {/* Phone Number */}
          <div className="col-md-6 col-12 row mx-0 mt-1">
            <label className="control-field__label col-12">{operationStatement.PhoneNumber}</label>
            {/* phone code */}
            <div className="col-4 company_phone_code">
              <SelectField
                label={"Code"}
                hasLabel={false}
                id="guide-phone_code"
                name="guide-phone_code"
                placeholder={Locale.guideRequests.choose}
                value={
                  guideData?.data?.phone_code?.flag ?
                    <img
                      src={`${fetchFlag}/${guideData?.data?.phone_code?.flag}`}
                      width="35px"
                      alt={guideData?.data.phone_code?.label}
                    />
                    :
                    guideData?.data.phone_code?.label || guideData?.data.phone_code
                }
                onChange={(e) => handleGuideInputsChanges({ key: "phone_code", value: e })}
                options={allCountries}
                color={errors?.phone_code?.required ? "danger" : ""}
                errors={errors?.phone_code}
              />
            </div>
            {/* phone */}
            <div className="col-8 px-0">
              <NumberField
                type={"phone"}
                label={Locale.guideRequests.phoneNumber}
                hasLabel={false}
                id="guide-phone"
                name="guide-phone"
                value={guideData?.data.phone}
                removeArrow={true}
                placeholder={"000 0000 000"}
                min={3}
                onChange={(e) => handleGuideInputsChanges({ key: "phone", value: e.target.value })}
                extraTextPosition="prepend"
                extraText={
                  guideData?.data?.phone_code?.phone_code ||
                  guideData?.data.phone_code?.label ||
                  guideData?.data?.phone_code?.value
                }
                color={errors?.phone?.required || errors?.phone_code?.required ? "danger" : ""}
                errors={errors?.phone || errors?.phone_code}
              />
            </div>
          </div>

          {/* desciption */}
          <div className="col-12 textarea-container mt-1">
            <label htmlFor="guide-description" className="control-field__label">{Locale.guideRequests.description}</label>
            <textarea
              name="guide-description"
              id="guide-description"
              rows={8}
              className={`my-1 ${errors?.description?.required ? "control-field--danger" : ""} `}
              value={guideData.data?.description}
              onChange={(e) => handleGuideInputsChanges({ key: "description", value: e.target.value })}
            />
            {errors?.description?.required &&
              <small className="control-field__feedback--danger mt-1">{guideRequests.descriptionIsRequired}</small>
            }
          </div>


          {/* Assign Delegation Form */}

          {/* Attach Type */}
          <div className="d-flex align-items-center gap-24  w-100 my-4 flex-wrap">
            {!isServiceCompany &&
              <div className="d-flex align-items-center ">
                <input
                  id="company"
                  name="company"
                  type="radio"
                  class="mx-2 radio-input"
                  checked={officialData.attach_type === "company"}
                  value={"company"}
                  onChange={(e) => setOfficialData({ ...officialData, attach_type: e.target.value })}
                />
                <label htmlFor="company" className="mb-0 pointer user-select-none">
                  {guideRequests.delegateManagementCompany}
                </label>
              </div>
            }

            <div className="d-flex align-items-center">
              <input
                id="officer"
                name="officer"
                type="radio"
                class="mx-2 radio-input"
                checked={officialData.attach_type === "officer"}
                value={"officer"}
                onChange={(e) => setOfficialData({ ...officialData, attach_type: e.target.value })}
              />
              <label htmlFor="officer" className="mb-0 pointer user-select-none">
                {guideRequests.myDelegates}
              </label>
            </div>
          </div>

          {/* inputs */}
          <div className="row mx-0  w-100 mt-3">
            {(officialData.attach_type === "company" && !isServiceCompany)
              ?
              // Company
              <div className="col-12">
                <SelectField
                  label={guideRequests.selectCompany}
                  name="delegate_company"
                  id="delegate_company"
                  options={lookups.companies}
                  // value={officialData?.company?.name || guideData.data?.request?.delegation_company_name}
                  value={officialData?.company?.name}
                  onChange={(e) => {
                    setOfficialData({
                      ...officialData,
                      company: e
                    });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "company", value: e },
                        { required: true }
                      ),
                    });
                  }}

                  errors={errors?.company}
                  color={errors?.company?.required ? "danger" : ""}
                />
              </div>
              : null
            }

            {/* if user choose to select from exist officers */}
            {(officialData.attach_type === "officer")
              ?
              <>
                {/* groups */}
                <div className="col-12 col-md-6">
                  <SelectField
                    label={guideRequests.selectGroup}
                    name="group"
                    id="group"
                    options={lookups.groups || []}
                    value={officialData.group?.name}
                    onChange={(e) => {
                      setOfficialData({
                        ...officialData,
                        group: e,
                        officer: {
                          id: null,
                          name: "",
                          phone_code: "",
                          phone_number: "",
                          email: "",
                        },
                        officer_id: null
                      });
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: "group", value: e },
                          { required: true }
                        ),
                      });
                      // getOfficersFromGroup({ groupId: e.id });
                    }}
                    errors={errors?.group}
                    color={errors?.group?.required ? "danger" : ""}
                  />
                </div>

                {/* officers */}
                {/* <div className="col-6">
                  <SelectField
                    label={guideRequests.selectDelegates}
                    name="delegates"
                    id="delegates"
                    options={lookups?.officers || []}
                    value={officialData.officer?.name}
                    onChange={(e) => {
                      setOfficialData({
                        ...officialData,
                        officer: e,
                        officer_id: e?.id
                      });
                      console.log(e)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: "officer_id", value: e },
                          { required: true }
                        ),
                      });
                    }}
                    errors={errors?.officer_id}
                    color={errors?.officer_id?.required ? "danger" : ""}
                    disabled={!officialData.group}
                  />
                </div> */}

                {/* phone number with country code */}
                {/* <div className="col-md-6 col-12 row mx-0 mt-1">
                  <label className="control-field__label col-12">{operationStatement.PhoneNumber}</label>
                  phone code
                  <div className="col-4 company_phone_code">
                    <SelectField
                      label={guideRequests.code}
                      hasLabel={false}
                      id="officer-phone_code"
                      name="officer-phone_code"
                      placeholder={"Select"}
                      value={
                        officialData?.officer?.phone_code?.flag ?
                          <img
                            src={`${fetchFlag}/${officialData?.officer?.phone_code?.flag}`}
                            width="35px"
                            alt={officialData?.officer?.phone_code?.label}
                          />
                          :
                          officialData?.officer?.phone_code?.label || officialData?.officer?.phone_code
                      }
                      options={allCountries}
                      disabled={true}
                    />
                  </div>
                  phone
                  <div className="col-8 px-0">
                    <NumberField
                      type={guideRequests.phone}
                      label={operationStatement.PhoneNumber}
                      hasLabel={false}
                      id="officer-phone"
                      name="officer-phone"
                      value={officialData?.officer?.phone_number}
                      removeArrow={true}
                      placeholder={"000 0000 000"}
                      min={3}
                      extraTextPosition="prepend"
                      extraText={officialData?.officer?.phone_code?.label || officialData?.officer?.phone_code?.phone_code}
                      disabled={true}
                    />
                  </div>
                </div> */}

                {/* email */}
                {/* <div className="col-6">
                  <TextField
                    label={guideRequests.email}
                    type="email"
                    id="officer-email"
                    name="officer-email"
                    value={officialData?.officer?.email}
                    disabled={true}
                  />
                </div> */}
              </>
              : null
            }

            {showAttachFileInput &&
              <div className="col-12 mt-3">
                <UploadInput
                  key={officialData.attach_type}
                  label={`${operationStatement.attach} (PDF, Text, Doc)`}
                  onUpload={handleUploadFile}
                  accept=".pdf, .txt, .doc, .docx, image/png, image/jpeg"
                  bucket="official"
                  errorMsg={errors.attach_file?.required ? movementManagement.attachFile + " " + commons.isRequired : ""}
                />
              </div>
            }

          </div>

          {/* action button */}
          <div className='col-12 mt-1'>
            <button className="btn save-btn"
              onClick={() => handleCreateUpdateGroup(guideData.id)}
            >
              <SaveIcon color="#D4A655" />
              {guideRequests.save}
            </button>
          </div>

        </div>
      </ModalBody>
    </Modal>
  )
}
