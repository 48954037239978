import React, { useEffect, useState } from 'react';
// import { ReactComponent as OperatingStatementIcon } from "assets/images/umrah-operations/operation-statement-icon.svg";
import { ReactComponent as OperatingCenterIcon } from "assets/images/umrah-operations/operation-statement-icon.svg";
import { ReactComponent as AccountingIcon } from "assets/images/umrah-operations/accounting-icon.svg";
import { ReactComponent as AgentsIcon } from "assets/images/umrah-operations/agents-icon.svg";
// import { ReactComponent as StatisticsIcon } from "assets/images/umrah-operations/pie-chart-icon.svg";
import { ReactComponent as CarIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
import { ReactComponent as HeadPhoneIcon } from "assets/images/svg/headphones.svg";
// import OperationTabs from './shared/Tabs';
import { useParams, useLocation } from 'react-router-dom';
import OperationStatement from './OperationStatement';
import UmrahOperationsAgents from './Agents';
import OperationStatementView from './OperationStatement/View';
import Locale from 'translations';
import { ClockIcon, DangerIcon, MessageQuestionIcon, NoteIcon, SettingIcon, UserTagIcon } from './shared/Icons';
import OperationAddEditAgent from './Agents/AddEditAgent';
import OperationCenter from './OperationsCenter';
import TimingSettings from './Settings/Timing';
import BasicSettings from './Settings/Basic';
import AccountingStatement from './Accounting';
import ViewEditAccounting from './Accounting/ViewEdit';
import ShowSubscription from 'helpers/ShowSubscription';
// import Statistics from './Statistics';
import UmrahOperationsOrdersViolations from './OrdersViolations';
import AddEditViolationsOrder from './OrdersViolations/AddEditOrder';
import OfficialMovementContainer from './MovementsManagement/Official';
import OperationsGuideRequests from './GuideRequests';
// import { useSBSState } from 'context/global';
import DelegationManagementContainer from './MovementsManagement/Delegations';

import AccountingAgents from './Accounting/Agents';
import ViewAccountingAgent from './Accounting/Agents/view';


export default function UmrahOperations() {
  // const { companyInfo } = useSBSState();
  const { tab, id, reference } = useParams();
  const { pathname } = useLocation();
  const { operationStatement, movementManagement, operationAccounting, ordersViolations, guideRequests } = Locale;
  // const isServiceCompnay = companyInfo?.company_category === "services_company";

  const operationTabsItems = [
    // operating Statement
    {
      title: operationStatement.operatingStatement,
      icon: <NoteIcon />,
      url: "operation-statement",
      permission: ["View-operation-statement", "Manage-operation-statement"],
      subscriptionModule: "operation_statement"
    },
    // operationsCenter
    {
      title: operationStatement.operationsCenter,
      icon: <OperatingCenterIcon />,
      url: "operations-center",
      permission: ["View-operation-center", "Manage-operation-center"],
      subscriptionModule: "operation_center"
    },

    // Movements Management
    {
      title: movementManagement.movementManagement,
      icon: <OperatingArrivalDepartureIcon />,
      url: "movements",
      permission: [
        "View-Delegation-Management",
        "Manage-Delegation-Management",
        "View-Official-Management",
        "Manage-Official-Management"
      ],
      subTabs: [
        {
          title: movementManagement.movement,
          icon: <CarIcon />,
          url: "delegations-management",
          parentUrl: "movements",
          permission: ["View-Delegation-Management", "Manage-Delegation-Management",],
          subscriptionModule: "delegation_management"
        },
        {
          title: movementManagement.delegatesManagement,
          icon: <UserTagIcon color='#292D32' />,
          url: "official-movement",
          parentUrl: "movements",
          permission: ["View-Official-Management", "Manage-Official-Management"],
          subscriptionModule: "official_management"
        },
      ]
    },

    // Accounting
    {
      title: operationAccounting.accounting, icon: <AccountingIcon />,
      url: "accounting",
      permission: [
        "View-operation-accounting",
        "Manage-operation-accounting",
        "View-Agent-Accounting",
        "Manage-Agent-Accounting"
      ],
      subTabs: [
        {
          title: operationAccounting.operationAccounting,
          icon: <SettingIcon color='#292D32' />,
          parentUrl: "accounting",
          url: "operation",
          permission: ["View-operation-accounting", "Manage-operation-accounting",],
          subscriptionModule: "operation_accounting"
        },
        {
          title: operationAccounting.agentAccounting,
          icon: <ClockIcon color='#292D32' />,
          parentUrl: "accounting",
          url: "agent",
          permission: ["View-Agent-Accounting", "Manage-Agent-Accounting"],
          subscriptionModule: "agent_accounting"
        },
      ]
    },

    // Orders Violations
    {
      title: ordersViolations.ordersViolations,
      icon: <DangerIcon />,
      url: "orders-violations",
      permission: ["View-Order-Violation", "Manage-Order-Violation"],
      subscriptionModule: "order_violation"
    },

    // Guide Requests
    {
      title: guideRequests.guideRequests,
      icon: <MessageQuestionIcon />,
      url: "guide-requests",
      permission: ["View-Guide-Request", "Manage-Guide-Request"],
      subscriptionModule: "guide_request"
    },

    // agents
    {
      title: operationStatement.agents,
      icon: <AgentsIcon />,
      url: "agents",
      permission: ["View-Operation-Agent", "Manage-Operation-Agent"],
      subscriptionModule: "operation_agent"
    },

    // settings
    {
      title: operationStatement.settings,
      icon: <SettingIcon color='#707170' />,
      url: "settings",
      permission: ["View-Operation-Setting", "Manage-Operation-Setting"],
      subscriptionModule: "operation_setting",
      subTabs: [
        {
          title: operationStatement.basicSettings,
          icon: <SettingIcon color='#292D32' />,
          url: "basic-settings",
          permission: ["View-Operation-Setting", "Manage-Operation-Setting"],
          parentUrl: "settings"
        },
        {
          title: operationStatement.timingSettings,
          icon: <ClockIcon color='#292D32' />,
          url: "timing-settings",
          permission: ["View-Operation-Setting", "Manage-Operation-Setting"],
          parentUrl: "settings"
        },
      ]
    },
    // { title: operationStatement.statistics, icon: <StatisticsIcon />, url: "statistics" },

  ];

  const [activeTab, setActiveTab] = useState(!tab ? operationTabsItems[0].url : tab);
  useEffect(() => {
    if (id === undefined) {
      setActiveTab(tab);
      // push(`/operations/${activeTab}`);
    } else {
      setActiveTab(tab);
      // push(`/operations/${tab}/${id}`);
    }
  }, [id, tab]);


  return (
    <>
      {/* <div className="bg-gray-50 vertical-content-tabs">
        <OperationTabs
          tabs={isServiceCompnay ? operationTabsItems.filter(tab => tab.url === "movements" || tab.url === "guide-requests") : operationTabsItems}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          classNames="px-4 col-md-12 col-12 mx-auto"
        />
      </div> */}
      {/* TODO change this list  to routes */}
      <div className="container-fluid umrah-operations-wrapper">
        {/* operation statement */}
        {activeTab === "operation-statement" ?
          // <ShowSubscription module="operation_statement">
          //   <OperationStatement />
          // </ShowSubscription>
          <OperationStatement />
          : null
        }
        {activeTab === "operation-view" ?
          // <ShowSubscription module="operation_statement">
          //   <OperationStatementView />
          // </ShowSubscription>
          <OperationStatementView />
          : null
        }

        {/* operation center */}
        {activeTab === "operations-center" ?
          <ShowSubscription module="operation_center">
            <OperationCenter />
          </ShowSubscription>
          : null
        }

        {/* operation accounting */}
        {activeTab === "accounting" && pathname.split("/").pop() === "operation" ?
          <ShowSubscription module="operation_accounting">
            <AccountingStatement />
          </ShowSubscription>

          : null
        }
        {activeTab === "accounting" && pathname.split("/").slice(1, -1).pop() === `operation-view` ?
          <ShowSubscription module="operation_accounting">
            <ViewEditAccounting />
          </ShowSubscription>

          : null
        }
        {activeTab === "accounting" && pathname.split("/").slice(1, -1).pop() === `operation-edit` ?
          <ShowSubscription module="operation_accounting">
            <ViewEditAccounting />
          </ShowSubscription>

          : null
        }

        {/* agent accounting */}
        {activeTab === "accounting" && pathname.split("/").pop() === `agent` ?
          <ShowSubscription module="agent_accounting">
            <AccountingAgents />
          </ShowSubscription>
          : null
        }
        {activeTab === "accounting" && pathname.split("/").slice(1, -1).pop() === `agent-statement` ?
          <ShowSubscription module="agent_accounting">
            <ViewAccountingAgent />
          </ShowSubscription>
          : null
        }
        {activeTab === "accounting" && pathname.split("/").slice(1, -1).pop() === `agent-violations` ?
          <ShowSubscription module="agent_accounting">
            <ViewAccountingAgent />
          </ShowSubscription>
          : null
        }

        {/* order violation */}
        {activeTab === "orders-violations" && reference === undefined ?
          <ShowSubscription module="order_violation">
            <UmrahOperationsOrdersViolations />
          </ShowSubscription>
          : null
        }

        {
          (activeTab === "orders-violations" && reference === "add") ||
            (activeTab === "orders-violations" && reference === "view") ||
            (activeTab === "orders-violations" && reference === "edit") ?
            <ShowSubscription module="order_violation">
              <AddEditViolationsOrder />
            </ShowSubscription>
            : null
        }

        {/* official management */}
        {activeTab === "movements" && pathname.split("/").pop() === "official-movement" ?
          <ShowSubscription module="official_management">
            <OfficialMovementContainer />
          </ShowSubscription>
          : null
        }

        {/* delegation management */}
        {activeTab === "movements" && pathname.split("/").pop() === "delegations-management" ?
          <ShowSubscription module="delegation_management">
            <DelegationManagementContainer />
          </ShowSubscription>
          : null
        }

        {/* guide request */}
        {activeTab === "guide-requests" ?
          <ShowSubscription module="guide_request">
            <OperationsGuideRequests />
          </ShowSubscription>
          : null
        }

        {/* operation agents */}
        {activeTab === "agents" && reference === undefined ?
          // <ShowSubscription module="operation_agent">
          //   <UmrahOperationsAgents />
          // </ShowSubscription>
          <UmrahOperationsAgents />
          : null
        }
        {
          (activeTab === "agents" && reference === "add") ||
            (activeTab === "agents" && reference === "view") ||
            (activeTab === "agents" && reference === "edit") ?
            // <ShowSubscription module="operation_agent">
            //   <OperationAddEditAgent />
            // </ShowSubscription> 
            <OperationAddEditAgent />
            : null
        }

        {/* operation setting */}
        {activeTab === "settings" && pathname.split("/").pop() === "timing-settings" ?
          <ShowSubscription module="operation_setting">
            <TimingSettings />
          </ShowSubscription>
          : null
        }
        {activeTab === "settings" && pathname.split("/").pop() === "basic-settings" ?
          <ShowSubscription module="operation_setting">
            <BasicSettings />
          </ShowSubscription>
          : null
        }


        <div className="live-chat-holder">
            <a className='live-chat' href="http://bit.ly/2LGuZ5a" target="_blank" rel="noopener noreferrer">
              <HeadPhoneIcon />
              <span className='live-chat-text'>
                {operationStatement?.live_support}
              </span>
            </a>
        </div>

        {/* {activeTab === "statistics" ? <Statistics /> : null} */}
      </div>
    </>
  );
}
