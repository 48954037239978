import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import SelectField from 'components/Form/SelectField/SelectField';
import TimePickerField from 'components/Form/TimePickerField/TimePickerField';
import validate, { isFormValid } from 'helpers/validate';
import { PlusIcon } from "modules/UmrahOperations/shared/Icons";
import { TrashIcon } from '../../icons';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import Locale from 'translations';
import { attractionsNamesLookup } from 'services/operationStatement';
import { useState } from 'react';
import { createAttraction } from 'services/tripOperations';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { initialAttractionData } from '../multiStepFormConfig';

const ATTRACTIONS_REQUIRED_FIELDS = ["city", "attractionsName", "attractionsDate", "attractionsTime"]

const catchOnlyRequiredFields = (fields, requiredFields) => {
  const keys = Object.keys(fields);
  const requiredFieldsKeys = requiredFields;
  const requiredFieldsValues = keys.filter((key) => requiredFieldsKeys.includes(key));
  return requiredFieldsValues.reduce((acc, key) => {
    acc[key] = fields[key];
    return acc;
  }, {});
};

export default function AttractionsForm({ formId, formState, setFormState }) {
  const { operationStatement } = Locale;
  const attractions = formState.attractions;
  const { id } = useParams()
  const { citiesLookup } = getOperationsLookup(Locale);

  const handleInputChange = ({ key, value, index, required }) => {
    setFormState((prev) => ({
      ...prev,
      attractions: prev.attractions.map((attraction, i) => {
        if (i === index) {
          return {
            ...attraction,
            data: {
              ...attraction.data,
              [key]: value,
            },
            errors: {
              ...attraction.errors,
              ...validate({ name: key, value }, { required }),
            },
          };
        }
        return attraction;
      }
      ),
    }));
  };

  const addAttraction = () => {
    setFormState((prev) => ({
      ...prev,
      attractions: [...prev.attractions, initialAttractionData],
    }));
  };

  const deleteAttraction = (index) => {
    setFormState((prev) => ({
      ...prev,
      attractions: prev.attractions.filter((_, i) => i !== index),
    }));
  };

  const checkErrors = () => {
    let _errors = [];
    attractions.forEach((attraction, index) => {
      let _row_error = {};
      Object.keys(catchOnlyRequiredFields(attraction.data, ATTRACTIONS_REQUIRED_FIELDS)).forEach((key) => {
        _row_error = {
          ..._row_error,
          ...validate({ name: key, value: attraction.data[key] }, { required: true }),
        };
      });
      _errors.push(_row_error);
    });

    setFormState((prev) => ({
      ...prev,
      attractions: prev.attractions.map((attraction, index) => {
        return {
          ...attraction,
          errors: _errors[index],
        };
      }),
    }));

    return _errors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = checkErrors();
    const isFormValidFlag = formErrors.every((error) => isFormValid(error));
    if (!isFormValidFlag) return;

    const payload = {
      attractions: attractions.map(attraction => ({
        attraction_id: attraction.data.attractionsName.id,
        city_id: attraction.data.city.id,
        date_time: moment(attraction.data.attractionsDate).format("YYYY-MM-DD") + " " + moment(attraction.data.attractionsTime).format("HH:mm")
      }))
    }

    // API Call
    await createAttraction(id, payload);
  }

  return (
    <>
      <div className="attractions-form-wrapper">
        <form id={formId} className="attractions-form" onSubmit={handleSubmit}>
          {
            attractions.map((attraction, index) =>
              <AttractionRow
                key={index}
                attraction={attraction}
                index={index}
                citiesLookup={citiesLookup}
                handleInputChange={handleInputChange}
                deleteAttraction={deleteAttraction}
              />
            )
          }

          <button className="add-button" type='button' onClick={addAttraction}>
            <span className="icon">
              <PlusIcon />
            </span>
            {operationStatement.AddAttraction}
          </button>
        </form>
      </div>
    </>
  )
}

const AttractionRow = ({ attraction, index, citiesLookup, handleInputChange, deleteAttraction }) => {
  const { operationStatement, safaVisa } = Locale;
  const [attractionsLookup, setAttractionsLookup] = useState([]);


  return (
    <div className='attractions-inputs-row'>
      <div>
        <SelectField
          label={operationStatement.City}
          placeholder={operationStatement.Select}
          value={attraction.data.city?.name || ""}
          onChange={async (e) => {
            handleInputChange({ key: "city", value: e, index, required: false })
            const res = await attractionsNamesLookup({ city_id: e.id })
            setAttractionsLookup(res.data?.data || [])
          }}
          options={citiesLookup}
          errors={attraction.errors.city}
          color={attraction.errors.city?.required ? "danger" : ""}
        />
      </div>

      <div>
        <SelectField
          label={operationStatement.AttractionsName}
          placeholder={operationStatement.Select}
          value={attraction.data.attractionsName?.name || ""}
          onChange={(e) => handleInputChange({ key: "attractionsName", value: e, index, required: true })}
          options={attractionsLookup}
          errors={attraction.errors.attractionsName}
          color={attraction.errors.attractionsName?.required ? "danger" : ""}
          disabled={!attraction.data.city}
        />
      </div>

      <div>
        <DatePickerField
          label={safaVisa.attractionsDate}
          placeholder={operationStatement.Select}
          date={attraction.data.attractionsDate || null}
          onDateChange={(date) => handleInputChange({ key: "attractionsDate", value: date, index, required: true })}
          errors={attraction.errors.attractionsDate}
          color={attraction.errors.attractionsDate?.required ? "danger" : ""}
        />
      </div>

      <div>
        <TimePickerField
          label={safaVisa.attractionsTime}
          placeholder={operationStatement.Select}
          date={attraction.data.attractionsTime || null}
          onChangeDate={(date) => handleInputChange({ key: "attractionsTime", value: date, index, required: true })}
          errors={attraction.errors.attractionsTime}
          color={attraction.errors.attractionsTime?.required ? "danger" : ""}
        />
      </div>

      <div className='delete-btn-wrapper'>
        <button type="button" onClick={() => deleteAttraction(index)}><TrashIcon /></button>
      </div>
    </div>
  )
}