import { useEffect, useRef, useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import DefualtPricesTaxes from '../shared/DefualtPricesTaxes';
// Servicies
import {
  addFlightService,
  editFlightService,
  fetchCountryPortsWithSearch,
  getServicesById
} from 'services/serviveBuilder';
// Helper
import validate, { isFormValid } from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Custom Hook
import useSharedData from '../constant/useSharedData';
import { debounce } from "lodash"
import useDebounce from 'hooks/useDebounce';
import TermsAndConditionsField from '../shared/TermsAndConditionsField';
// -------------------------------------------------------------------------------------------------

const ServiceBuilderFlightForm = () => {
  // *** hooks
  const { Service_Builder, inventory, commons } = Locale

  const history = useHistory()

  const { status, id } = useParams()

  const { allCountries } = useSBSState()

  const { requiredFlightInputs, handleAutoTranslate, isPayloadValid } = useSharedData()

  let goingToPrevValue = useRef(null);

  // ** states
  const [flightData, setFlightData] = useState({
    serviceId: '',
    name_ar: "",
    name_en: "",
    destination: '',
    from_port: '',
    to_port: '',
    description_en: "",
    description_ar: "",
    images: [],
    price: "",
    currency: "SAR",
    taxes: [{
      name: '',
      type: '',
      value: '',
    }]
  });

  const [showPricing, setShowPricing] = useState(false)
  const [destenationOnSearch, setDestenationOnSearch] = useState('');
  const [errors, setErrors] = useState({});
  // const [flights, setFlights] = useState({});
  const [listAuto, setListAuto] = useState([]);
  const [listAutoTo, setListAutoTO] = useState([]);
  const [taxesIsSelected, setTaxesIsSelected] = useState("");
  const [selectedLang, setSelectedLang] = useState("en");

  // *** logic functions
  function handleFlightInputsChanges({ key, value }) {
    let flightDataClone = { ...flightData }
    flightDataClone[`${key}`] = value;
    setFlightData({ ...flightDataClone });
  }

  function checkFormErrors() {
    let submitError = {};
    requiredFlightInputs?.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: flightData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }


  // AutoCompleteField port from
  const getListAuto = async (inputValue) => {
    if (inputValue.length > 2) {
      const Airlines = await fetchCountryPortsWithSearch(null, inputValue);
      let result = [];
      Airlines.map((item) => {
        result.push({
          name: item.name,
          id: item.id,
          value: item.id,
          label: item.name,
        });
      });
      setListAuto(result);
    }
  };

  // AutoCompleteField port to 
  const getListAutoTO = async (inputValue) => {
    const countryId = flightData?.destination?.country_id || flightData?.destination?.id
    if (inputValue.length > 2) {
      const Airlines = await fetchCountryPortsWithSearch(countryId, inputValue);
      let result = [];
      Airlines.map((item) => {
        result.push({
          name: item.name,
          id: item.id,
          value: item.id,
          label: item.name,
        });
      });
      setListAutoTO(result);
    }
  };

  const debouncedGetListAuto = useDebounce((searchText) => getListAuto(searchText), 600);
  const debouncedGetListAutoTo = useDebounce((searchText) => getListAutoTO(searchText), 600);

  function clearSearchText(e, key) {
    goingToPrevValue.current = destenationOnSearch[key];
    const value = e.target.value;
    if (value.length > 0) {
      setDestenationOnSearch({ ...destenationOnSearch, [key]: null });
    }
  }

  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return
    }
    let requestBody = {
      name: {
        en: flightData?.name_en,
        ar: flightData?.name_ar,
      },
      destination_id: flightData?.destination?.id || flightData?.destination?.country_id
      ,
      transporter_id: null,
      from_port_id: flightData?.from_port?.id,
      to_port_id: flightData?.to_port?.id,
      description: {
        en: flightData?.description_en,
        ar: flightData?.description_ar,
      },
      terms: {
        en: flightData?.terms_en,
        ar: flightData?.terms_ar,
      },
      images: flightData?.images,
      price: showPricing ? (flightData?.price || null) : null,
      currency: flightData?.currency,
      // taxes: flightData?.taxes || null
      // taxes: isPayloadValid(flightData?.taxes?.[0]) ? flightData?.taxes : null
      taxes: taxesIsSelected ? flightData?.taxes : []
    }
    if (status === 'add') {
      const response = await addFlightService(requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    } else if (status === 'edit') {
      const response = await editFlightService(flightData?.serviceId, requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    }
  }

  const fetchFlightData = async () => {
    const response = await getServicesById(id)
    if (response?.status === 200) {
      setFlightData({
        serviceId: response?.data?.data?.service.id,
        name_en: response?.data?.data?.service?.name?.en,
        name_ar: response?.data?.data?.service?.name?.ar,
        destination: response?.data?.data?.service?.destination,
        from_port: response?.data?.data?.service?.from_port,
        to_port: response?.data?.data?.service?.to_port,
        description_en: response?.data?.data?.service?.description,
        description_ar: "",
        terms_en: response?.data?.data?.terms?.en,
        terms_ar: response?.data?.data?.terms?.ar,
        images: response?.data?.data?.service?.images,
        price: response?.data?.data?.service?.price,
        currency: response?.data?.data?.service?.currency,
        taxes: response?.data?.data?.service?.taxes
      })
    }
    setShowPricing(response?.data?.data?.service?.price ? true : false)
    return response
  }

  useEffect(() => {
    if (status === 'edit' && id) {
      fetchFlightData()
    }
  }, [])

  // console.log(flightData)

  // ------------ JSX Code -------
  return (
    <div >
      <h1 className='form-title'>
        {Service_Builder?.select_flight_details}
      </h1>

      <form onSubmit={submit} className="mt-4 ">

        {/* Flight Service Form  */}
        <div className='row form-holder mx-auto mb-5 align-items-start'>

          {/* Service Name en */}
          <div className="col-12 col-md-4 custom-field mt-2">
            <TextField
              type="text"
              label={Service_Builder?.service_name_en}
              hasLabel={status === 'edit' ? true : false}
              placeholder={Service_Builder?.service_name_en}
              name="name_en"
              id="name_en"
              value={flightData?.name_en}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'name_en', value: e?.target?.value });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required ? "danger" : ""}
              errors={errors?.name_en}
            />
          </div>

          {/* Service Name ar */}
          <div className="col-12 col-md-4 custom-field mt-2">
            <TextField
              type="text"
              label={Service_Builder?.service_name_ar}
              hasLabel={status === 'edit' ? true : false}
              placeholder={Service_Builder?.service_name_ar}
              name="name_ar"
              id="name_ar"
              value={flightData?.name_ar}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'name_ar', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_ar", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_ar?.required ? "danger" : ""}
              errors={errors?.name_ar}
            />
          </div>

          <div className="col-12 col-md-4 mt-2" />

          {/* Destenation */}
          <div className="col-12 col-md-4 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.destination}
              placeholder={Service_Builder?.destination}
              id="destination"
              name="destination"
              value={flightData?.destination?.name}
              options={allCountries}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'destination', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "destination", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.destination?.required ? "danger" : ""}
              errors={errors?.destination}
            />
          </div>

          {/* From Airport (Optional) */}
          <div className="col-12 col-md-4 custom-field mt-3">
            <div className="form-group">
              <AutoCompleteField
                disabled={!flightData?.destination}
                hasLabel={status === 'edit' ? true : false}
                labelInner={false}
                isImage={false}
                // image={fromIcom}
                label={Service_Builder?.from_airPort_optional}
                isSearchable={true}
                placeholder={Service_Builder?.from_airPort_optional}
                listAuto={listAuto}
                setListAuto={setListAuto}
                getListAuto={debouncedGetListAuto}
                value={flightData?.from_port?.name}
                // flag={flights.from?.country?.flag}
                onFocus={(e) => clearSearchText(e, "from")}
                onBlur={() =>
                  // setFlights({
                  //   ...flights,
                  //   from: goingToPrevValue.current,
                  // })
                  setFlightData({ ...flightData, from_port: goingToPrevValue.current })
                }
                onChange={(e) => {
                  // setFlights({
                  //   ...flights,
                  //   from: { name: e },
                  // });
                  setFlightData({ ...flightData, from_port: { name: e } })
                }}
                onSelectValue={(selectedObj) => {
                  // setFlights({
                  //   ...flights,
                  //   from: selectedObj,
                  // });
                  handleFlightInputsChanges({ key: 'from_port', value: selectedObj });
                  setErrors({
                    ...errors,
                    ...validate({ name: "from_port", value: selectedObj }, { required: false }),
                  });
                }}
              // color={errors?.from_port?.required ? "danger" : ""}
              // errors={errors?.from_port}
              />
              <i class="fas fa-exchange-alt d-none"></i>
            </div>
          </div>

          {/* To AirPort (Optional )*/}
          <div className="col-12 col-md-4 custom-field mt-3">
            <div className="form-group">
              <AutoCompleteField
                disabled={!flightData?.destination}
                hasLabel={status === 'edit' ? true : false}
                labelInner={false}
                label={Service_Builder?.to_airPort_optional}
                isSearchable={true}
                isImage={false}
                // image={toIcon}
                placeholder={Service_Builder?.to_airPort_optional}
                listAuto={listAutoTo}
                setListAuto={setListAutoTO}
                getListAuto={debouncedGetListAutoTo}
                value={flightData?.to_port?.name}
                // flag={flights.to?.country?.flag}
                onFocus={(e) => clearSearchText(e, "to")}
                onBlur={() =>
                  // setFlights({
                  //   ...flights,
                  //   to: goingToPrevValue.current,
                  // })
                  setFlightData({ ...flightData, to_port: goingToPrevValue.current })
                }
                onChange={(e) => {
                  // setFlights({
                  //   ...flights,
                  //   to: { name: e },
                  // });
                  setFlightData({ ...flightData, to_port: { name: e } })
                }}
                onSelectValue={(selectedObj) => {
                  // setFlights({
                  //   ...flights,
                  //   to: selectedObj,
                  // });
                  handleFlightInputsChanges({ key: 'to_port', value: selectedObj });
                  setErrors({
                    ...errors,
                    ...validate({ name: "to_port", value: selectedObj }, { required: false }),
                  });
                }}
              // color={errors?.to_port?.required ? "danger" : ""}
              // errors={errors?.to_port}
              />
            </div>
          </div>

          {/* Flight Description*/}
          <div className="col-12 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.description}
              height={'126px'}
              placeholder={Service_Builder?.description}
              value={flightData?.description_en}
              onChange={(e) => {
                handleFlightInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }
              }
              color={errors?.description_en?.required ? "danger" : ""}
              errors={errors?.description_en}
            />
          </div>

          {/* TermsAndConditionsField */}
          <div className="col-12  custom-field mt-3">
            <TermsAndConditionsField
              hasLabel={status === 'edit' ? true : false}
              value={flightData?.[`terms_${selectedLang}`] || ''}
              onChange={(e) => {
                handleFlightInputsChanges({ key: `terms_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `terms_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`terms_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`terms_${selectedLang}`]}
              handleTranslate={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: flightData?.[`terms_${selectedLang}`] })
                  .then(result => handleFlightInputsChanges({ key: `terms_${selectedLang}`, value: result }))
              }
              selectedLang={selectedLang}
              setSelectedLang={setSelectedLang}
            />
          </div>

          {/* Flight Iamges*/}
          <div className="col-12 mt-3">
            <div className='images-holder d-flex  align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                value={flightData.images}
                onChange={(images) => {
                  handleFlightInputsChanges({ key: "images", value: images })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images.length, },
                      { required: true }
                    ),
                  });
                }}
                errorMsg={
                  errors?.images?.required
                    ? inventory.viewReservation.Photos + " " + commons.isRequired
                    : false
                }
              />
            </div>
          </div>

        </div>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />

          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/*Flight Pricing and Taxes*/}
        {showPricing &&
          <DefualtPricesTaxes
            pricingState={{
              price: flightData?.price,
              currency: flightData?.currency,
              taxes: flightData?.taxes,
            }}
            setPricingState={(data) => setFlightData((prevData) => ({ ...prevData, ...data }))}
            onSelectTaxes={(value) => setTaxesIsSelected(value)}
          />}

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {status === 'edit' ? Service_Builder?.save_changes : Service_Builder?.save}
            </span>
          </button>
        </div>

      </form>
    </div>
  )
}

export default ServiceBuilderFlightForm