import SelectField from 'components/Form/SelectField/SelectField';
import validate, { isFormValid } from 'helpers/validate';
import { PlusIcon } from "modules/UmrahOperations/shared/Icons";
import TextField from 'components/Form/TextField/TextField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import { AccommodationIcon, TrashIcon } from '../../icons';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import Locale from 'translations';
import { useState } from 'react';
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import { fetchHotels } from 'services/operationStatement';
import { createHotel } from 'services/tripOperations';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { cityInitialData } from '../multiStepFormConfig';

// const CITY_REQUIRED_FIELDS = [];
const HOTEL_REQUIRED_FIELDS = ["hotelName"];


const catchOnlyRequiredFields = (fields, requiredFields) => {
  const keys = Object.keys(fields);
  const requiredFieldsKeys = requiredFields;
  const requiredFieldsValues = keys.filter((key) => requiredFieldsKeys.includes(key));
  return requiredFieldsValues.reduce((acc, key) => {
    acc[key] = fields[key];
    return acc;
  }, {});
};

export default function HotelForm({ formId, formState, setFormState }) {
  const { operationStatement, marketPlace, productsBuilder } = Locale
  const { id } = useParams()
  const { mealsTypesLookup, citiesLookup } = getOperationsLookup(Locale);


  const handleInputCityInputChange = ({ key, value, index, required }) => {
    setFormState((prev) => {
      const cities = [...prev.hotels.cities];
      cities[index] = {
        ...cities[index],
        data: {
          ...cities[index].data,
          [key]: value,
        },
        errors: {
          ...cities[index].errors,
          ...validate({ name: key, value }, { required }),
        },
      };

      return {
        ...prev,
        hotels: {
          ...prev.hotels,
          cities,
        },
      };
    });
  };

  const handleInputHotelInputChange = ({ key, value, cityIndex, hotelIndex, required }) => {
    setFormState((prev) => {
      const cities = [...prev.hotels.cities];
      const hotels = [...cities[cityIndex].data.hotels];
      hotels[hotelIndex] = {
        ...hotels[hotelIndex],
        data: {
          ...hotels[hotelIndex].data,
          [key]: value,
        },
        errors: {
          ...hotels[hotelIndex].errors,
          ...validate({ name: key, value }, { required }),
        },
      };

      cities[cityIndex] = {
        ...cities[cityIndex],
        data: {
          ...cities[cityIndex].data,
          hotels,
        },
      };

      return {
        ...prev,
        hotels: {
          ...prev.hotels,
          cities,
        },
      };
    });
  };

  const checkErrors = () => {
    const cityErrors = []
    const hotelErrors = []

    formState.hotels.cities.forEach((city, index) => {
      let _cityErrors = {}
      Object.keys(city.data).forEach((key) => {
        _cityErrors = {
          ..._cityErrors,
          ...validate({ name: key, value: city.data[key] }, { required: true }),
        }
      });

      city.data.hotels.forEach((hotel, hotelIndex) => {
        let _hotelErrors = {}
        Object.keys(catchOnlyRequiredFields(hotel.data, HOTEL_REQUIRED_FIELDS)).forEach((key) => {
          _hotelErrors = {
            ..._hotelErrors,
            ...validate({ name: key, value: hotel.data[key] }, { required: true }),
          }
        });
        hotelErrors.push(_hotelErrors)
      });

      cityErrors.push(_cityErrors)
    });

    setFormState((prev) => {
      const cities = [...prev.hotels.cities];
      cities.forEach((city, index) => {
        // debugger;
        cities[index] = {
          ...cities[index],
          errors: {
            ...cities[index].errors,
            ...cityErrors[index],
          },
          data: {
            ...cities[index].data,
            hotels: [...cities[index].data.hotels].map((hotel, hotelIndex) => {
              return {
                ...hotel,
                errors: {
                  ...hotel.errors,
                  ...hotelErrors[hotelIndex],
                },
              };
            })
          },
        };
      });

      return {
        ...prev,
        hotels: {
          ...prev.hotels,
          cities,
        },
      };
    });

    return { cityErrors, hotelErrors }
  };

  const addCity = () => {
    setFormState((prev) => ({
      ...prev,
      hotels: {
        ...prev.hotels,
        cities: [...prev.hotels.cities, cityInitialData],
      },
    }));
  };

  const deleteCity = (index) => {
    setFormState((prev) => {
      const cities = [...prev.hotels.cities];
      cities.splice(index, 1);
      return {
        ...prev,
        hotels: {
          ...prev.hotels,
          cities,
        },
      };
    });
  }

  const addHotel = (cityIndex) => {
    const cities = [...formState.hotels.cities];
    const hotels = [...cities[cityIndex].data.hotels];
    hotels.push(cityInitialData.data.hotels[0]);
    cities[cityIndex].data.hotels = hotels;

    setFormState((prev) => ({
      ...prev,
      hotels: {
        ...prev.hotels,
        cities
      },
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { cityErrors, hotelErrors } = checkErrors();
    const isCitiesValid = cityErrors.every(city => isFormValid(city));
    const isHotelsValid = hotelErrors.every(hotel => isFormValid(hotel));

    if (!isCitiesValid || !isHotelsValid) return;

    const payload = {
      cities: formState.hotels.cities.map((city) => {
        return {
          city_id: city.data.city.id,
          check_in_date: moment(city.data.checkIn).format("YYYY-MM-DD"),
          check_out_date: moment(city.data.checkOut).format("YYYY-MM-DD"),
          nights: +city.data.nightsNumber,
          hotels: city.data.hotels.map((hotel, index) => {
            return {
              hotel: hotel.data.hotelName,
              hotel_id: hotel.data.hotelName.id,
              check_in_date: moment(city.data.checkIn).format("YYYY-MM-DD"),
              check_out_date: moment(city.data.checkOut).format("YYYY-MM-DD"),
              nights: +city.data.nightsNumber,
              no_nights: +city.data.nightsNumber,
              rooms_count: {
                meal_type: hotel.data.mealsType?.id,
                single: hotel.data.singleCount,
                double: hotel.data.doubleCount,
                triple: hotel.data.tripleCount,
                quadruple: hotel.data.quadCount,
                quintuple: hotel.data.quintCount,
                sixtuple: hotel.data.sextCount,
              },
              meal_type: hotel.data.mealsType?.id,
              single: hotel.data.singleCount,
              double: hotel.data.doubleCount,
              triple: hotel.data.tripleCount,
              quadruple: hotel.data.quadCount,
              quintuple: hotel.data.quintCount,
              sixtuple: hotel.data.sextCount,
            }
          })
        }
      })
    }
    await createHotel(id, payload)
  }

  return <div className="hotel-form-wrapper">
    <form id={formId} className="hotel-form" onSubmit={handleSubmit}>
      {formState.hotels.cities.map((city, index) => (
        <div className='city'>
          <h3 className='city-title'>{operationStatement.City} ({index + 1})</h3>

          <div>
            <div className='city-top-fields'>
              <div className='city-select-field'>
                <SelectField
                  label={operationStatement.City}
                  placeholder={marketPlace.messages.selectCity}
                  value={city.data?.city?.name || ""}
                  onChange={(e) => handleInputCityInputChange({ key: "city", value: e, index, required: true })}
                  options={citiesLookup}
                  errors={city.errors.city}
                  color={city.errors.city?.required ? "danger" : ""}
                />
              </div>

              <div className='buttons-group'>
                <button
                  className="add-button"
                  type="button"
                  onClick={() => addHotel(index)}
                >
                  <span className="icon">
                    <PlusIcon />
                  </span>
                  {operationStatement.AddHotel}
                </button>

                <span className='splitter'>|</span>

                <button
                  className="delete-button"
                  type="button"
                  onClick={() => deleteCity(index)}
                >
                  <span className="icon">
                    <TrashIcon />
                  </span>
                  {operationStatement.DeleteCity}
                </button>
              </div>
            </div>

            <div className="city-fields-card">
              <div>
                <DatePickerField
                  label={operationStatement.CheckinDate}
                  date={city.data?.checkIn || null}
                  onDateChange={date => {
                    handleInputCityInputChange({ key: "checkIn", value: date, index, required: true })
                    if (date && city.data.checkOut) {
                      const nights = moment(city.data.checkOut).diff(date, 'days');
                      handleInputCityInputChange({ key: "nightsNumber", value: nights, index, required: false })

                    }
                  }}
                  errors={city.errors.checkIn}
                  color={
                    city.errors?.checkIn?.required ? "danger" : ""
                  }
                />
              </div>

              <div>
                <TextField
                  label={marketPlace.noOfNights}
                  placeholder={productsBuilder.enterNumber}
                  type='number'
                  value={city.data?.nightsNumber || ""}
                  onChange={(e) => handleInputCityInputChange({ key: "nightsNumber", value: e.target.value, index, required: true })}
                  errors={city.errors.nightsNumber}
                  color={city.errors.nightsNumber?.required ? "danger" : ""}
                  disabled
                />
              </div>

              <div>
                <DatePickerField
                  label={operationStatement.CheckoutDate}
                  date={city.data?.checkOut || null}
                  onDateChange={date => {
                    handleInputCityInputChange({ key: "checkOut", value: date, index, required: true })
                    if (date && city.data.checkIn) {
                      const nights = moment(date).diff(city.data.checkIn, 'days');
                      handleInputCityInputChange({ key: "nightsNumber", value: nights, index, required: false })
                    }
                  }}
                  errors={city.errors.checkOut}
                  color={
                    city.errors?.checkOut?.required ? "danger" : ""
                  }
                />
              </div>
            </div>

            {
              city.data.hotels.map((hotel, hotelIndex) => {
                return (
                  <div className='hotel-fields-wrapper'>
                    <div className='hotel-select-fields'>
                      <div className='hotel-name-select'>
                        <HotelNameField
                          value={hotel.data?.hotelName?.name || hotel.data?.hotelName || ""}
                          onChange={(e) => handleInputHotelInputChange({ key: "hotelName", value: e, cityIndex: index, hotelIndex, required: true })}
                          onSelectValue={(e) => handleInputHotelInputChange({ key: "hotelName", value: e, cityIndex: index, hotelIndex, required: true })}
                          errors={hotel.errors.hotelName}
                          color={hotel.errors.hotelName?.required ? "danger" : ""}
                          cityID={city.data?.city?.id}
                        />
                      </div>

                      <div className='hotel-meal-type-select'>
                        <SelectField
                          label={marketPlace.mealType}
                          placeholder={marketPlace.mealType}
                          value={hotel.data?.mealsType?.name || ""}
                          options={mealsTypesLookup}
                          onChange={(e) => handleInputHotelInputChange({ key: "mealsType", value: e, cityIndex: index, hotelIndex, required: false })}
                          errors={hotel.errors.mealsType}
                          color={hotel.errors.mealsType?.required ? "danger" : ""}
                        />
                      </div>
                    </div>

                    <div className='counters-wrapper'>
                      <Counter
                        name={marketPlace.x1}
                        value={hotel.data?.singleCount || 0}
                        onInc={() => handleInputHotelInputChange({ key: "singleCount", value: hotel.data.singleCount + 1, cityIndex: index, hotelIndex, required: false })}
                        onDec={() => handleInputHotelInputChange({ key: "singleCount", value: hotel.data.singleCount - 1, cityIndex: index, hotelIndex, required: false })}
                      />
                      <Counter
                        name={marketPlace.x2}
                        value={hotel.data?.doubleCount || 0}
                        onInc={() => handleInputHotelInputChange({ key: "doubleCount", value: hotel.data.doubleCount + 1, cityIndex: index, hotelIndex, required: false })}
                        onDec={() => handleInputHotelInputChange({ key: "doubleCount", value: hotel.data.doubleCount - 1, cityIndex: index, hotelIndex, required: false })}
                      />
                      <Counter
                        name={marketPlace.x3}
                        value={hotel.data?.tripleCount || 0}
                        onInc={() => handleInputHotelInputChange({ key: "tripleCount", value: hotel.data.tripleCount + 1, cityIndex: index, hotelIndex, required: false })}
                        onDec={() => handleInputHotelInputChange({ key: "tripleCount", value: hotel.data.tripleCount - 1, cityIndex: index, hotelIndex, required: false })}
                      />
                      <Counter
                        name={marketPlace.x4}
                        value={hotel.data?.quadCount || 0}
                        onInc={() => handleInputHotelInputChange({ key: "quadCount", value: hotel.data.quadCount + 1, cityIndex: index, hotelIndex, required: false })}
                        onDec={() => handleInputHotelInputChange({ key: "quadCount", value: hotel.data.quadCount - 1, cityIndex: index, hotelIndex, required: false })}
                      />
                      <Counter
                        name={marketPlace.x5}
                        value={hotel.data?.quintCount || 0}
                        onInc={() => handleInputHotelInputChange({ key: "quintCount", value: hotel.data.quintCount + 1, cityIndex: index, hotelIndex, required: false })}
                        onDec={() => handleInputHotelInputChange({ key: "quintCount", value: hotel.data.quintCount - 1, cityIndex: index, hotelIndex, required: false })}
                      />
                      <Counter
                        name={marketPlace.x6}
                        value={hotel.data?.sextCount || 0}
                        onInc={() => handleInputHotelInputChange({ key: "sextCount", value: hotel.data.sextCount + 1, cityIndex: index, hotelIndex, required: false })}
                        onDec={() => handleInputHotelInputChange({ key: "sextCount", value: hotel.data.sextCount - 1, cityIndex: index, hotelIndex, required: false })}
                      />
                    </div>
                  </div>
                )
              })
            }

          </div>
        </div>
      )
      )}

      <button
        type="button"
        onClick={addCity}
        className="add-button"
      >
        <span className="icon">
          <PlusIcon />
        </span>
        {operationStatement.AddCity}
      </button>
    </form>
  </div>;
}

const Counter = ({ name, value, onInc, onDec }) => {
  return (
    <div className='counter'>
      <div className='counter-desc'>
        <span className='icon'><AccommodationIcon /></span>
        <span>{name}</span>
      </div>
      <div className='counter-actions'>
        <button onClick={onDec} disabled={value === 0} type='button'>-</button>
        <span>{value}</span>
        <button onClick={onInc} type='button'>+</button>
      </div>
    </div>
  )
}

const HotelNameField = ({ value, onChange, cityID, onSelectValue, ...props }) => {
  const [hotelsList, setHotelsList] = useState([]);
  const { productsBuilder } = Locale;

  const getListAuto = async (inputValue) => {
    if (inputValue.length > 2) {
      const Hotels = await fetchHotels("966", cityID, inputValue);

      let result = Hotels.hotels.map((item) => {
        return {
          ...item,
          id: item.id,
          name: item.name,
          value: item.id,
          label: item.name,
        };
      });
      setHotelsList(result);
    }
  };


  // useEffect(() => {
  //   getListAuto(value);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <AutoCompleteField
      hasLabel={true}
      listAuto={hotelsList}
      setListAuto={setHotelsList}
      getListAuto={getListAuto}
      label={productsBuilder.hotel}
      isSearchable={true}
      placeholder={productsBuilder.hotel}
      value={value}
      onChange={onChange}
      onSelectValue={onSelectValue}
      {...props}
    />
  )
}