import React, { useEffect, useState } from "react";
import OperationStatementFilter from "./Filter";
import BreadCrump from "modules/UmrahOperations/shared/BreadCrump";
import Locale from "translations";
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import TextField from "components/Form/TextField/TextField";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import OperationStatementList from "./List";
import {
	// checkUserBasicSettings,
	getAgentsLookup,
	getStatementsList,
	operationStatementExportToExcel,
} from "services/operationStatement";
import moment from "moment";
import Pagination from "components/Pagination";
import { useSBSState } from "context/global";
import { store } from "react-notifications-component";
import ShowForPermission from "helpers/showForPermission";
import NusukCreateOperationModal from './modals/NusukCreateOperationModal';
// import { useHistory } from 'react-router-dom';
// import ShowSubscription from 'helpers/ShowSubscription';

export default function OperationStatement() {
	const { operationStatement } = Locale;
	const { allCountries, locale } = useSBSState();
	// const history = useHistory();
	const [statementsList, setStatementsList] = useState(null);
	const allItem = { id: 'all', value: 'all', label: operationStatement.All, name: operationStatement.All }
	const initialFilters = {
		search: "",
		agent_ids: [allItem],
		country_ids: [allItem],
		trip_types: [allItem],
		departure_dates: null,
		arrival_dates: null,
		hotel_cities: [allItem],
		check_in_dates: null,
		check_out_dates: null,
		transporter_ids: [],
		status: "all",
		page: 1,
	};
	const [filters, setFilters] = useState(initialFilters);
	const [statusCount, setStatusCount] = useState({
		all: 0,
		approved: 0,
		draft: 0,
		new: 0,
		rejected: 0,
	});
	const [createOpModalOpen, setCreateOpModalOpen] = useState(false);
	const [metaData, setMetaData] = useState(null);
	const [Reload, setReload] = useState(false);

	const [lookups, setLookups] = useState({
		countries: [allItem, ...allCountries],
		agents: [],

		tripTypes: [
			{ name: operationStatement.All, label: operationStatement.All, id: "all", value: "all" },
			{ name: "Land", label: "Land", id: 1, value: "Land" },
			{ name: "Air", label: "Air", id: 2, value: "Air" },
			{ name: "Sea", label: "Sea", id: 3, value: "Sea" },
		],

		hotelCitiesLookup: [
			{ name: operationStatement.All, label: operationStatement.All, id: "all", value: "all" },
			{ label: "MECCA", name: " MECCA", id: 3 },
			{ label: "AL_MADINAH", name: "AL_MADINAH", id: 4 },
			{ label: "RIYADH", name: "RIYADH", id: 194 },
			{ label: "JEDDAH", name: "JEDDAH", id: 134 },
			{ label: "TAIF", name: "TAIF", id: 214 },
			{ label: "YANBU", name: "YANBU", id: 224 },
		],
	});
	const [userShouldAddSerial, setUserShouldAddSerial] = useState(false);
	// const [userHasBasicSettings, setUserHasBasicSettings] = useState(null);



	function toggleCreateOperationModal() {
		setCreateOpModalOpen(!createOpModalOpen)
	}
	// handle paginations
	function goTo(page) {
		// setFilters({ ...filters, page: page });
		fetchStatementList(null, page)
	}

	function formatFilters(filters) {
		const filtersData = {
			status: filters?.status !== "all" ? filters?.status : null,
			search: filters?.search,
			country_ids: filters?.country_ids?.find(
				(country) => country?.value === "all"
			)
				? null
				: filters?.country_ids?.map((country) => country?.id),
			agent_ids: filters?.agent_ids?.find((agent) => agent?.value === "all")
				? null
				: filters?.agent_ids?.map((agent) => agent?.id),
			trip_types: filters?.trip_types?.find((type) => type?.value === "all")
				? null
				: filters?.trip_types?.map((type) => type?.label),
			hotel_cities: filters?.hotel_cities?.find(
				(hotel) => hotel?.value === "all"
			)
				? null
				: filters?.hotel_cities?.map((hotel) => hotel?.id),
			check_in_dates: filters?.check_in_dates
				? [
					moment(filters?.check_in_dates[0]).format("YYYY-MM-DD"),
					moment(filters?.check_in_dates[1]).format("YYYY-MM-DD"),
				]
				: null,
			check_out_dates: filters?.check_out_dates
				? [
					moment(filters?.check_out_dates[0]).format("YYYY-MM-DD"),
					moment(filters?.check_out_dates[1]).format("YYYY-MM-DD"),
				]
				: null,
			departure_dates: filters?.departure_dates
				? [
					moment(filters?.departure_dates[0]).format("YYYY-MM-DD"),
					moment(filters?.departure_dates[1]).format("YYYY-MM-DD"),
				]
				: null,
			arrival_dates: filters?.arrival_dates
				? [
					moment(filters?.arrival_dates[0]).format("YYYY-MM-DD"),
					moment(filters?.arrival_dates[1]).format("YYYY-MM-DD"),
				]
				: null,
			transporter_ids: filters?.transporter_ids?.map(
				(transporter) => transporter?.id,
			),
			page: filters?.page
		};
		return filtersData;
	}
	// reset filters
	function resetFilters() {
		setFilters(initialFilters);
		if (initialFilters?.status === filters?.status) {
			fetchStatementList("reset");
		}
	}
	// get operation statment data with filters
	async function fetchStatementList(type, page) {
		let filtersData = formatFilters(
			type === "reset"
				? initialFilters
				: { ...filters, page }
		);
		const res = await getStatementsList(filtersData);
		if (res?.status === 200) {
			setStatementsList(res?.data?.data);
			setStatusCount(res?.data?.status_counts);
			setMetaData(res?.data?.meta);
			setUserShouldAddSerial(res?.data?.should_add_serial_code)
		}
	}

	useEffect(() => {
		fetchStatementList();
	}, [filters?.status, Reload]);

	// check if user has basic settings
	// useEffect(() => {
	// 	async function checkUserHasBasicSettings() {
	// 		const res = await checkUserBasicSettings();
	// 		if (res?.status === 200 && !res?.data?.has_basic_settings) {
	// 			history.push('/operations/settings/basic-settings');
	// 		}
	// 		setUserHasBasicSettings(res?.data?.has_basic_settings);
	// 	}
	// 	if (userHasBasicSettings === null) {
	// 		checkUserHasBasicSettings()
	// 	}
	// }, [history, userHasBasicSettings])

	// useEffect(() => {
	// 	function countriesLookups() {
	// 		setLookups({
	// 			...lookups,
	// 			countries: [allItem, ...allCountries],
	// 		});
	// 	}
	// 	countriesLookups();
	// }, [allCountries]);

	useEffect(() => {
		async function fethcAgentLookups() {
			let res = await getAgentsLookup();
			let formatAgents = res?.data?.data?.map((agent) => {
				return {
					...agent,
					country: { ...agent?.country, label: agent?.country?.name?.[locale], value: String(agent?.country?.country_id) },
					value: agent?.id,
					label: `${agent?.name} - ${agent?.country?.name?.[locale]}`,
					name: `${agent?.name} - ${agent?.country?.name?.[locale]}`,
				};
			});
			setLookups({
				...lookups,
				agents: [allItem, ...formatAgents],
			});
		}
		fethcAgentLookups();
	}, []);

	const exportExcel = async () => {
		const res = await operationStatementExportToExcel({
			...formatFilters({ ...filters, status: filters?.status }),
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`operation-statement-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};



	return (
		<div
			className="operation-statement-container"
		>
			{statementsList ? (
				<>
					<BreadCrump title={operationStatement.operatingStatement}>
						{/* <div className="text-filed-visibility-hidden">
							<TextField
								type="text"
								id="statement-operation-no"
								name="statement-operation-no"
								hasLabel={false}
								placeholder={operationStatement.quickOperationNo}
								isImage={true}
								image={SeachIcon}
								prependImage={true}
							// onChange={(e) => {
							//   setFilters({
							//     ...filters,
							//     search: e.target.value,
							//   });
							// }}
							// value={filters?.search}
							/>
						</div> */}
						<ShowForPermission permission="Manage-operation-statement">
							<button className="btn py-2 filled-btn flex-grow-1 flex-sm-grow-0 justify-content-center" onClick={toggleCreateOperationModal}>
								Nusuk Create Operation
							</button>
							<button
								className="btn export-excel-btn py-2 flex-grow-1 flex-sm-grow-0 justify-content-center"
								onClick={exportExcel}
							>
								<ExportExcelIcon />
								<span className="mx-1">{operationStatement.exportExcel}</span>
							</button>
						</ShowForPermission>
					</BreadCrump>

					<OperationStatementFilter
						filters={filters}
						setFilters={setFilters}
						allItem={allItem}
						resetFilters={resetFilters}
						fetchStatementList={fetchStatementList}
						lookups={lookups}
					/>
					<ShowForPermission permission="View-operation-statement">
						<OperationStatementList
							statementsList={statementsList}
							filters={filters}
							statusCount={statusCount}
							setFilters={setFilters}
							fetchStatementList={fetchStatementList}
							setReload={setReload}
							agentsList={lookups.agents}
							reload={Reload}
							userShouldAddSerial={userShouldAddSerial}
						/>
						<div className="mt-4 pb-3">
							<Pagination info={metaData} goTo={goTo} />
						</div>
					</ShowForPermission>
				</>
			) : null}

			{createOpModalOpen &&
				<NusukCreateOperationModal
					isOpen={createOpModalOpen}
					toggle={toggleCreateOperationModal}
				/>
			}
		</div>
	);
}
