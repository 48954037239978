import { useWebBuilderState } from 'context/webBuilder';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import usePreviewMode from "hooks/usePreviewMode";
import Locale from 'translations';

export default function OtherCardDefault(props) {
  const { selectedLanguage } = useWebBuilderState();
  const { webBuilder } = Locale;
  const { isPreview } = usePreviewMode();
  
  const { openEditModal, image, name, name_localized, product_uuid, description } = props;

  return (
    <div className="otherservice-card">

      <div className="card-img">
        {!isPreview && !image ? (
          <button className="btn add-service-btn"
            onClick={() => openEditModal(props)}
          >
            <AddIcon />
          </button>
        ) : null}

        {image
          ? <img src={image} alt="transfer" width={100} />
          : null
        }
      </div>

      <div className="card-details">
        <h5 className="title">
          {name_localized ? name_localized[selectedLanguage] : name}
        </h5>
        <p className="description">
          {description}
        </p>
        <button className="btn cta-btn">
          {webBuilder.viewMore}
        </button>
      </div>
    </div>
  )
}
