import BreadCrump from 'modules/UmrahOperations/shared/BreadCrump'
import React, { useEffect, useState } from 'react'
import Locale from 'translations';
import DelegationManagementFilter from './Filters';
import DelegationManagementTableRow from './TableRow';
import { fetchAllOfficersLookup, fetchDelegationsList, movementManagementExportToExcel } from 'services/operationStatement';
import Pagination from 'components/Pagination';
import ChooseOfficialModal from 'modules/UmrahOperations/shared/ChooseOfficialModal';
import useOperationMovementsModals from 'modules/UmrahOperations/OperationsCenter/useOperationMovementsModals';
import ExecuteMovementModal from 'modules/UmrahOperations/shared/ExecuteMovementModal';
import { useSBSState } from 'context/global';
import ShowSubscription from 'helpers/ShowSubscription';
import EditHallModal from 'modules/UmrahOperations/OperationsCenter/Modals/EditHallModal';
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import moment from 'moment';
import { store } from 'react-notifications-component';
import { PlusIcon } from 'modules/UmrahOperations/shared/Icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EditTrasportationModal from 'modules/UmrahOperations/OperationsCenter/Modals/EditTrasportationModal';




export default function DelegationManagementContainer() {
  // ** hooks
  const { companyInfo } = useSBSState();
  const history = useHistory();
  const { operationStatement, marketPlace, inventory, productElements, productsBuilder, backOffice, movementManagement } = Locale;
  const {
    toggleOfficerModal,
    officerModal,
    saveOfficerData,
    toggleExecuteModal,
    executeModalState,
    handleExecuteMovement,
    toggleHallModal,
    hallModal,
    setHallModal,
    sendHallData,
    toggleTransportationModal,
    transportationModal,
    setTransportaionModal,
    sendTransportationData,
  } = useOperationMovementsModals(resetFilters);

  const isServiceCompany = companyInfo?.company_category === "services_company";

  let initalFitlers = {
    search: "",
    date: null,
    page: null,
    officer: null,
  }

  // ** states
  const [delegationsList, setDelegationsList] = useState([]);
  const [officersList, setOfficersList] = useState([]);
  const [meta, setMeta] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [filters, setFilters] = useState(initalFitlers);

  // ** functions

  function handleFiltersChanges({ key, value }) {
    setFilters({ [key]: value })
    //   setFilters({ ...filters, [key]: value })
  }

  function resetFilters() {
    setFilters(initalFitlers);
    // getDelegationsList(initalFitlers)
  }


  function goTo(page) {
    getDelegationsList({ ...filters, page })
  };

  async function getDelegationsList(filters) {
    const res = await fetchDelegationsList(filters)
    if (res?.status === 200) {
      setDelegationsList(res?.data?.data);
      setMeta(res?.data?.meta)
      setStatistics(res?.data?.stats)
    }
  }

  async function getOfficersList(search) {
    const res = await fetchAllOfficersLookup(search)
    if (res?.status === 200) {
      setOfficersList(res?.data?.data);
    }
  }

  function formatFilters(filters) {
    const filtersData = {
      search: filters?.search,
      page: filters?.page,
      date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null,
      is_executed: filters?.is_executed,
      officer_id: filters?.officer?.id || filters?.officer_id,
    };
    return filtersData;
  }

  const exportExcel = async () => {
    const res = await movementManagementExportToExcel({
      ...formatFilters({ ...filters }),
    });

    if (res?.status === 200 || res?.status === 201) {
      if (res.data) {
        const blob = new Blob([res.data], {
          type: res.data.type,
        });
        const objectUrl = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = objectUrl;
        anchor.setAttribute(
          "download",
          `operation-statement-${moment().format("DD-MM-YYYY")}`
        );
        anchor.click();
      }
      store.addNotification({
        title: "Info!",
        message: `Export To Excel Successful`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };


  useEffect(() => {
    getOfficersList()
  }, []);

  useEffect(() => {
    getDelegationsList({
      ...filters,
      date: filters?.date ? moment(filters?.date).format('YYYY-MM-DD') : null,
      officer: filters?.officer ? filters?.officer?.id : null
    });
  }, [filters]);

  console.log(filters)

  return (
    <ShowSubscription module="delegation_management">
      <div className="operations-center-container px-new-26">
        <BreadCrump title={movementManagement.movement} >
          {
            isServiceCompany &&
            <button
              className="btn filled-btn mx-2 py-2"
              onClick={() => history.push(`/operations/movements/delegations-management/add`)}
            >
              <PlusIcon color="#fff" />
              <span className="mx-1">{operationStatement.add_movement}</span>
            </button>
          }

          <button
            className="btn export-excel-btn mx-2 py-2"
            onClick={exportExcel}
          >
            <ExportExcelIcon />
            <span className="mx-1">{operationStatement.exportExcel}</span>
          </button>

        </BreadCrump>
        <DelegationManagementFilter
          filters={filters}
          handleFiltersChanges={handleFiltersChanges}
          getDelegationsList={getDelegationsList}
          resetFilters={resetFilters}
          getOfficersList={(search) => getOfficersList(search)}
          officersList={officersList}
          statistics={statistics}
        />

        <div className='operation-movements-table  table-container h-100'>
          <table className="table table-update mb-0">
            <thead>
              <tr>
                <th>{productElements.type}</th>
                <th>{operationStatement.Agent}</th>
                <th>{operationStatement.Supervisor}</th>
                <th>{marketPlace.messages.pax}</th>
                <th>{productsBuilder.From}</th>
                <th>{productsBuilder.To}</th>
                <th>{productElements.trip_number}</th>
                <th>{productElements.transportation_company_name}</th>
                <th>{operationStatement.alternative_transportation}</th>
                <th>{movementManagement.driver}</th>
                <th scope="col">{isServiceCompany ? movementManagement.requestedBy : movementManagement.requestToCompany}</th>
                <th>{operationStatement.movementOfficial}</th>
                <th>{productElements.status}</th>
                <th>{movementManagement.dateTime}</th>
                <th>{productElements.cycle_duration}</th>
                <th>{inventory.messages.Actions}</th>
              </tr>
            </thead>
            <tbody>
              {delegationsList?.length > 0 ?
                delegationsList?.map((delegationRow, index) => {
                  return (
                    <DelegationManagementTableRow
                      key={delegationRow?.id}
                      movmentItem={delegationRow}
                      toggleOfficerModal={toggleOfficerModal}
                      toggleExecuteModal={toggleExecuteModal}
                      handleExecuteMovement={handleExecuteMovement}
                      toggleHallModal={toggleHallModal}
                      toggleTransportationModal={toggleTransportationModal}
                    />
                  )
                })
                :
                <tr>
                  <td colSpan="10">
                    <div className="product-build__product-no-data fullHeight">
                      <i className="icx icx-eye-slash" aria-hidden="true"></i>
                      <h4>{backOffice.noResult}</h4>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>


        {/* list Pagination */}
        <div className="py-3">
          <Pagination
            info={meta}
            goTo={goTo}
          />
        </div>


        {officerModal.isOpen &&
          <ChooseOfficialModal
            isOpen={officerModal.isOpen}
            toggle={toggleOfficerModal}
            actionFn={saveOfficerData}
            isEdit={officerModal?.type === "edit"}
            data={officerModal?.data}
          />
        }

        {executeModalState.isOpen &&
          <ExecuteMovementModal
            isOpen={executeModalState.isOpen}
            toggle={toggleExecuteModal}
            actionFn={handleExecuteMovement}
          />
        }

        {hallModal.isOpen &&
          <EditHallModal
            toggle={toggleHallModal}
            hallData={hallModal}
            movmentsList={delegationsList}
            setHallModal={setHallModal}
            sendHallData={sendHallData}
          />
        }

        {transportationModal.isOpen &&
          <EditTrasportationModal
            toggle={toggleTransportationModal}
            transportationData={transportationModal}
            setTransportaionModal={setTransportaionModal}
            sendTransportationData={sendTransportationData}
          />
        }
      </div>
    </ShowSubscription>
  )
}

