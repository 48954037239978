import React, { useEffect, useState } from "react";
import BreadCrump from "../shared/BreadCrump";
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import { ReactComponent as EyelIcon } from "assets/images/Tds/eye.svg";
import PlusIcon from "modules/products-builder/Pricing/IconsSVG/PlusIcon";
import OperationsAgentsFilter from "./Filter";
import { useSBSState } from "context/global";
import { Table } from "reactstrap";
import Pagination from "components/Pagination";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import { agentExportToExcel, getAgentList } from "services/operationStatement";
import { store } from "react-notifications-component";
import moment from "moment";
import ShowForPermission from "helpers/showForPermission";
import ShowSubscription from 'helpers/ShowSubscription';

export default function UmrahOperationsAgents() {
	const {
		operationStatement,
		inventory,
		login,
		userDetails,
		productsBuilder,
		backOffice,
	} = Locale;
	const { allCountries, locale } = useSBSState();
	const history = useHistory();
	const initialFilters = {
		name: "",
		country_id: "",
		page: 1,
	};
	const [agentsList, setAgentList] = useState([]);
	const [meta, setMeta] = useState();
	const [filters, setFilters] = useState(initialFilters);
	const [countriesLookup, setCountriesLookup] = useState();

	const filesUrl = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

	function resetFilters() {
		setFilters(initialFilters);
	}

	function goTo(page) {
		setFilters({ ...filters, page: page });
	}

	async function fetchAgentsList() {
		const formatFilters = {
			...filters,
			country_id:
				filters?.country_id?.id === "all" ? null : filters?.country_id?.id,
		};
		const res = await getAgentList(formatFilters);
		if (res?.status === 200) {
			setAgentList(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	}

	useEffect(() => {
		fetchAgentsList();
	}, [filters]);

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries?.map((country) => ({
				id: country.id,
				label: country.name,
				name: country.name,
				code: country.country_code,
				phone_code: country.phone_code,
			}));
			setCountriesLookup([{ id: "all", label: "All", name: "All" }, ...format]);
		}
		countriesLookups();
	}, [allCountries]);
	const exportExcel = async () => {
		const res = await agentExportToExcel({
			...filters,
			country_id: filters?.country_id?.id,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Agent-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		// <ShowSubscription module="operation_agent">
			<div className="operations-agents px-new-26">
				<BreadCrump title={operationStatement.agents}>
					<ShowForPermission permission="Manage-operation-statement">
						<button className="btn export-excel-btn flex-grow-1 flex-md-grow-0 justify-content-center" onClick={exportExcel}>
							<ExportExcelIcon />
							<span className="mx-1">{operationStatement.exportExcel}</span>
						</button>
						<button
							className="btn bg-nxt d-flex align-items-center flex-grow-1 flex-md-grow-0 justify-content-center"
							onClick={() => history.push("agents/add")}
						>
							<PlusIcon />
							<span className="mx-2">{operationStatement.addAgent}</span>
						</button>
					</ShowForPermission>
				</BreadCrump>

				<OperationsAgentsFilter
					filters={filters}
					countriesLookup={countriesLookup}
					setFilters={setFilters}
					resetFilters={resetFilters}
				/>
				<ShowForPermission permission="View-operation-statement">
					<div className="table-container">
						<Table className=" p-2 bg-white table mb-0">
							<thead>
								<tr>
									<th>{operationStatement.agentName}</th>
									<th>{inventory.messages.country}</th>
									<th>{login.emailAddress}</th>
									<th>{userDetails.phoneNumber}</th>
									<th>{productsBuilder.actions}</th>
								</tr>
							</thead>
							<tbody>
								{agentsList?.length > 0 ? (
									agentsList?.map((agent) => {
										return (
											<tr key={agent?.id}>
												<td className="text-black-900">{agent?.name}</td>
												<td>
													<div className="d-flex align-items-center">
														<img
															src={`${filesUrl}/${agent?.country?.flag}`}
															alt={agent?.country?.name?.[locale]}
															className="rounded-circle"
															width={"16px"}
															height={"16px"}
														/>
														<span className="mx-1">
															{agent?.country?.name?.[locale]}
														</span>
													</div>
												</td>
												<td>{agent?.email}</td>
												<td>
													{agent?.phone_code}
													{agent?.phone}
												</td>
												<td>
													<button
														className="btn p-0 mx-2"
														onClick={() =>
															history.push(`agents/view/${agent?.id}`)
														}
													>
														<EyelIcon />
													</button>
													<button
														className="btn p-0"
														onClick={() =>
															history.push(`agents/view/${agent?.id}`)
														}
													>
														<EditIcon />
													</button>
												</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan="10">
											<div className="product-build__product-no-data fullHeight">
												<i className="icx icx-eye-slash" aria-hidden="true"></i>
												<h4>{backOffice.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
					<div className="mt-4 pb-3">
						<Pagination info={meta} goTo={goTo} />
					</div>
				</ShowForPermission>
			</div>
		// </ShowSubscription>
	);
}
