import { useEffect, useState } from "react";
import Header from "./Header";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	CloseIcon,
	SaveIcon,
} from "../icons";
import { useHistory } from "react-router-dom";
import { fetchTripData } from 'services/operationStatement';
import { useParams } from 'react-router-dom';
import { useSBSState } from 'context/global';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import Locale from 'translations';
import {
	hotelInitialData,
	initalArrivalDepartureData,
	initialAttractionData,
	initialDetailsData,
	transportationInitialData
} from './multiStepFormConfig';
import { mapArrivalDepartureData, mapAttractionsData, mapDetailsData, mapHotelData, mapTransportationData } from './apiDataMappers';
import {
  AttractionIcon,
  BuildingIcon,
  CarIcon,
  NoteIcon,
  PassportTravelIcon,
  RoutingIcon,
} from "../icons";
import { ArivalDepraureForm } from './ArrivalDeparture';
import HotelForm from './Hotel';
import AttractionsForm from './Attractions';
import { TransportationForm } from './Transportation';
import PassportForm from './Passport';
import DetailsForm from './Details';

export default function AddEditTripOperations() {
	const {operationStatement} = Locale;
	const history = useHistory();
	const { id } = useParams(); // if id exists then it's edit mode
	const { allCountries } = useSBSState();
	const [tripData, setTripData] = useState({});

	const [activeTab, setActiveTab] = useState(0);

	const { mealsTypesLookup, citiesLookup } = getOperationsLookup(Locale);

	const [formState, setFormState] = useState({
		details: initialDetailsData,
		arrival_departure: initalArrivalDepartureData,
		hotels: hotelInitialData,
		attractions: [initialAttractionData],
		transportation: transportationInitialData,
		travellers: [], // passport
	});

	const tabs = [
		{
			tab: {
				title: operationStatement.details,
				icon: <NoteIcon />,
			},
			form: DetailsForm,
			formId: "details",
		},
		{
			tab: {
				title: operationStatement.ArrivalDeparture,
				icon: <RoutingIcon />,
			},
			form: ArivalDepraureForm,
			formId: "arrival_departure",
		},
		{
			tab: {
				title: operationStatement.Hotels,
				icon: <BuildingIcon />,
			},
			form: HotelForm,
			formId: "hotels_form",
		},
		{
			tab: {
				title: operationStatement.attractions,
				icon: <AttractionIcon />,
			},
			form: AttractionsForm,
			formId: "attractions_form",
		},
		{
			tab: {
				title: operationStatement.passport,
				icon: <PassportTravelIcon />,
			},
			form: PassportForm,
			formId: "passport_form",
		},
		{
			tab: {
				title: operationStatement.transportation,
				icon: <CarIcon />,
			},
			form: TransportationForm,
			formId: "transportation_form",
		},
	];

	const Form = tabs[activeTab].form;

	useEffect(() => {
		const fetchData = async () => {
			if (id) {
				const res = await fetchTripData(id);
				if (res.status === 200) {
					const data = res.data.data;
					setFormState(prev => ({
						...prev,
						details: mapDetailsData(data, allCountries),
						arrival_departure: mapArrivalDepartureData(data.trip),
						hotels: mapHotelData(data.hotels, citiesLookup, mealsTypesLookup),
						attractions: mapAttractionsData(data, citiesLookup),
						transportation: mapTransportationData(data.transportation),
						travellers: data.travellers,
						errors: {},
					}));
					setTripData(data);
				}
			}
		};

		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, allCountries]);

	return (
		<div className="trip_operations addEditView">
			{id && <Header tripData={tripData} />}

			<div className="content">
				<div className="d-flex justify-content-between gap-2 flex-wrap align-items-center">
					<h2 className="title">{operationStatement.AddNewOperatingStatement}</h2>
					<div className="d-flex gap-1">
						<button
							className="navigation-btn back-btn"
							onClick={() =>
								setActiveTab((prev) => (prev === 0 ? prev : prev - 1))
							}
							disabled={activeTab === 0}
						>
							<ArrowLeftIcon />
							<span>Back</span>
						</button>
						<button
							className="navigation-btn next-btn"
							onClick={() =>
								setActiveTab((prev) =>
									prev === tabs.length - 1 ? prev : prev + 1
								)
							}
							disabled={activeTab === tabs.length - 1 || !id}
						>
							<span>Next</span>
							<ArrowRightIcon />
						</button>
					</div>
				</div>

				<div className="tabs">
					{tabs.map(({ tab }, index) => {
						const shouldDisable = !id && index > 0;
						return (
							<div className="tab">
								<button
									className={`${activeTab === index ? "active" : ""}`}
									onClick={() => setActiveTab(index)}
									disabled={shouldDisable}
								>
									<div className="icon">{tab.icon}</div>
									<div>{tab.title}</div>
								</button>
							</div>
						)
					})}
				</div>

				<div className="tabs-content">
					<Form
						formId={tabs[activeTab].formId}
						formState={formState}
						setFormState={setFormState}
					/>
				</div>
			</div>

			<div className="public-btns">
				<button
					className="public-close-btn"
					onClick={() => history.replace("/safa_visa/trip-operations")}
				>
					<CloseIcon />
					<span>{operationStatement.Close}</span>
				</button>
				<button className="public-save-btn" form={tabs[activeTab].formId}>
					<SaveIcon />
					<span>{operationStatement.Save}</span>
				</button>
			</div>
		</div>
	);
}

