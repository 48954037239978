import TextField from 'components/Form/TextField/TextField'
import React, { useRef, useState } from 'react'
import Locale from 'translations'
import SeachIcon from 'assets/images/market-place/servicesIcon/searchIcon.png'
import SelectField from 'components/Form/SelectField/SelectField'
import DateRangeField from 'components/Form/DateRangeField/DateRangeField'
import { ReactComponent as ResetFilterIcon } from 'assets/images/market-place/refresh-circle-icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/umrah-operations/search-icon.svg'
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField'
import { fetchHotels } from 'services/operationStatement'
import { useSBSState } from 'context/global'
import moment from 'moment'
import { ReactComponent as FilterIcon } from "assets/images/umrah-operations/filter-icon.svg";


const AccountingStatementFilter = ({
	filters,
	setFilters,
	getListOperation,
	lookups,
	allItem,
	resetFilters
}) => {
	// ** hooks
	const {
		marketPlace,
		operationStatement,
		productsBuilder,
		inventory,
		payment
	} = Locale
	const { locale } = useSBSState()
	const [hotelsList, setHotelsList] = useState([])
  const [isFilterOpen, setIsFilterOpen] = useState(false);

	const pricingType = [
		{ id: 'package', name: operationStatement.forPackage },
		{ id: 'service', name: operationStatement.forService }
	]

	let prevValue = useRef(null)

	const getHotelsList = async (inputValue, filterKey) => {
		if (inputValue.length > 2) {
			const hotelsRes = await fetchHotels(
				966,
				[3, 4, 194, 134, 214, 224],
				inputValue
			)
			let result = []
			hotelsRes?.hotels?.forEach((item) => {
				result.push({
					...item,
					name: item.name?.[locale] || item.name,
					value: item.id,
					label: item.name?.[locale] || item.name
				})
			})
			let hotels = { [filterKey]: result }
			setHotelsList(hotels)
		}
	}

	function clearSearchText(e, key) {
		prevValue.current = filters[key]
		const value = e.target.value
		if (value.length > 0) {
			setFilters({ ...filters, [key]: null })
		}
	}

	return (
		<>
		 {/* Floating Filter button */}
		 <div className="floating-filter-btn">
        <button className='btn filter-collapse-btn opened px-0'
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          <FilterIcon />
        </button>
      </div>	
		
		<div className={`operation-statement-filter ${isFilterOpen ? 'open-aside' : 'close-aside'}`}>
			<div className='d-flex w-100 d-md-none'>
				<button className="close-filter-btn" onClick={() => setIsFilterOpen(!isFilterOpen)}>
					X
				</button>
      </div>
			<div className='row w-100'>
				<div className='col-12 col-md-4'>
					<TextField
						type='text'
						id='statement-operation-no'
						name='statement-operation-no'
						label={marketPlace.messages.search}
						placeholder={operationStatement.searchEX}
						isImage={true}
						image={SeachIcon}
						prependImage={true}
						value={filters?.search}
						onChange={(e) => {
							setFilters({
								...filters,
								search: e.target.value
							})
						}}
					/>
				</div>

				<div className='col-12 col-md-4'>
					<AutoCompleteField
						label={productsBuilder.HotelName}
						placeholder={productsBuilder.select}
						id='hotel_id'
						name='hotel_id'
						listAuto={hotelsList?.['hotel_id']}
						setListAuto={setHotelsList}
						getListAuto={(e) => getHotelsList(e, 'hotel_id')}
						value={filters?.hotel_id?.name || ''}
						onFocus={(e) => clearSearchText(e, 'hotel_id')}
						onBlur={() =>
							setFilters({
								...filters,
								hotel_id: prevValue.current
							})
						}
						onChange={(e) => {
							setFilters({ ...filters, hotel_id: { name: e } })
						}}
						onSelectValue={(e) => {
							setFilters({ ...filters, hotel_id: e })
						}}
					/>
				</div>

				<div className='col-12 col-md-4 multi-select'>
					<SelectField
						multi
						id='statement-agent'
						name='statement-agent'
						label={operationStatement.agentName}
						options={
							filters?.agent_ids?.find((item) => item?.value === 'all')
								? []
								: lookups.agents
						}
						placeholder={filters.agent_ids}
						onChange={(values, { action }) => {
							if (action === 'remove-value' && !values) {
								setFilters({ ...filters, agent_ids: [] })
								return
							}
							if (values?.find((item) => item?.value === 'all')) {
								setFilters({ ...filters, agent_ids: [allItem] })
								return
							}
							setFilters({ ...filters, agent_ids: values })
						}}
					/>
				</div>

				<div className='col-12 col-md-4 multi-select'>
					<SelectField
						multi
						id='statement-country'
						name='statement-country'
						label={inventory.messages.country}
						options={
							filters?.country_ids?.find((item) => item?.value === 'all')
								? []
								: lookups.countries
						}
						placeholder={filters.country_ids}
						onChange={(values, { action }) => {
							if (action === 'remove-value' && !values) {
								setFilters({ ...filters, country_ids: [] })
								return
							}
							if (values?.find((item) => item?.value === 'all')) {
								setFilters({ ...filters, country_ids: [allItem] })
								return
							}
							setFilters({ ...filters, country_ids: values })
						}}
					/>
				</div>

				<div className='col-12 col-md-4'>
					<DateRangeField
						label={operationStatement.ArrivalDate}
						id='statement-departure-date'
						name='statement-departure-date'
						startDatePlaceholder={productsBuilder.startDatePlaceholder}
						endDatePlaceholder={productsBuilder.EndDatePlaceholder}
						startDate={filters?.arrival_dates?.[0]}
						endDate={filters?.arrival_dates?.[1]}
						onDatesChange={({ startDate, endDate }) => {
							endDate = endDate
								? moment(endDate).format('YYYY-MM-DD')
								: moment(startDate).add(1, 'd').format('YYYY-MM-DD')
							setFilters({
								...filters,
								arrival_dates: [moment(startDate), endDate]
							})
						}}
						isOutsideRange={(day) => {
							return false
						}}
						numberOfMonths={1}
					/>
				</div>

				<div className='col-12 col-md-4 multi-select'>
					<SelectField
						label={operationStatement?.pricingType}
						placeholder={productsBuilder.select}
						id='status'
						name='status'
						options={pricingType}
						value={filters?.pricing_type?.name}
						onChange={(e) => {
							setFilters({
								...filters,
								pricing_type: e
							})
						}}
					/>
				</div>

				<div className='d-flex flex-wrap align-items-center gap-1 col-12'>
					<button
						className='btn bg-nxt search-btn mt-0 flex-md-grow-0 flex-grow-1'
						onClick={() => {
							getListOperation()
							setIsFilterOpen(!isFilterOpen)
						}}
					>
						<SearchIcon />
						<span className='mx-2'>{marketPlace.messages.search}</span>
					</button>

					<button className='btn reset-btn mt-0 flex-md-grow-0 flex-grow-1' onClick={resetFilters}>
						<ResetFilterIcon />
						<span className='mx-2'>{payment.messages.clear}</span>
					</button>
				</div>
			</div>
		</div>
		</>
	)
}

export default AccountingStatementFilter
