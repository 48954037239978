import TextField from 'components/Form/TextField/TextField';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { ReactComponent as CalenderIcon } from 'assets/images/subscription-plan/calendar.svg'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import SelectField from 'components/Form/SelectField/SelectField';
import { ReactComponent as ResetFilterIcon } from 'assets/images/market-place/refresh-circle-icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/umrah-operations/search-icon.svg'
import Locale from 'translations';




export default function Filter({ filters, setFilters, handleSearch, handleReset }) {
  const { safaVisa, commons, CRM, operationStatement, productsBuilder } = Locale
  return (
    <div className='filter'>
      <div className='inputs'>
        <div>
          <TextField
            label={commons.search}
            placeholder={CRM.searchOrFilter}
            className='trip-id'
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </div>

        <div>
          <CustomDatePicker
            firstDate={filters?.arrival_dates[0]}
            secondDate={filters?.arrival_dates[1]}
            onChangeFirstDate={(date) => setFilters({ ...filters, arrival_dates: [date, filters.arrival_dates[1]] })}
            onChangeSecondDate={(date) => setFilters({ ...filters, arrival_dates: [filters.arrival_dates[0], date] })}
          />
        </div>

        <div>
          <SelectField
            label={CRM.Status}
            options={[
              { name: operationStatement.Rejected, id: 'rejected' },
              { name: operationStatement.Approved, id: 'approved' },
              { name: operationStatement.New, id: 'new' },
              { name: operationStatement.Draft, id: 'draft' },
            ]}
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.name })}
            placeholder={safaVisa.selectStatus}
          />
        </div>

        <div className='actions'>
          <button className='primary-button' onClick={handleSearch}>
            <SearchIcon />
            <span>{commons.search}</span>
          </button>

          <button className='primary-button reset-btn' onClick={handleReset}>
            <ResetFilterIcon />
            <span>{productsBuilder.clear}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

const CustomDatePicker = ({ firstDate, secondDate, onChangeFirstDate, onChangeSecondDate }) => {
  const { operationStatement } = Locale

  const [focused1, setFocused1] = useState(false);
  const [focused2, setFocused2] = useState(false);

  return (
    <div className="custom-date-picker">
      <label>{operationStatement.ArrivalDate}</label>
      <div className="inputs-group">

        {/* First Date Picker */}
        <div className="input">
          <SingleDatePicker
            date={firstDate}
            onDateChange={onChangeFirstDate}
            focused={focused1}
            onFocusChange={({ focused }) => setFocused1(focused)}
            id={`arrival_date_picker_1_`}
            numberOfMonths={1}
            small={true}
            block={false}
            showDefaultInputIcon={false}
            inputIconPosition="after"
            noBorder
            hideKeyboardShortcutsPanel
            displayFormat="DD/MM/YYYY"
            placeholder="Select date"
            customCloseIcon={null}
          />
          <CalenderIcon className="calendar-icon" />
        </div>

        {/* Second Date Picker */}
        <div className="input">
          <SingleDatePicker
            date={secondDate}
            onDateChange={onChangeSecondDate}
            focused={focused2}
            onFocusChange={({ focused }) => setFocused2(focused)}
            id={`arrival_date_picker_2_`}
            numberOfMonths={1}
            small={true}
            block={false}
            showDefaultInputIcon={false}
            inputIconPosition="after"
            noBorder
            hideKeyboardShortcutsPanel
            displayFormat="DD/MM/YYYY"
            placeholder="Select date"
            customCloseIcon={null}
          />
          <CalenderIcon className="calendar-icon" />
        </div>
      </div>
    </div>
  );
}