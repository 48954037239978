import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Locale from 'translations';
import useTravelersPassports from '../View/OperationElements/Passports/useTravelersPassports';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import moment from 'moment';
import NusukLoginForm from 'modules/UmrahOperations/shared/NusukLoginForm';
import { createStatemenetWithNusuk } from 'services/operationStatement';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { useHistory, useParams } from 'react-router-dom';

export default function NusukCreateOperationModal({ isOpen, toggle }) {
  // * hooks
  const { onlineVisa } = Locale;
  const {
    fetchNusukGroups,
    passportsGroups,
    hasNusukCredentials,
    setPassportsGroups,
    checkCompanyHasNusukCred,
    debouncedNusukGroupsSearch
  } = useTravelersPassports();

  const history = useHistory();
  const { bundle } = useParams()
  // ** states
  const [formState, setFormState] = useState({
    nusuk_group: null,
    rawda_reservation_date: null,
  })

  const [credReload, setCredReload] = useState(false);

  
  
  // *** functions
  function handleFormInputsChanges({ key, value }) {
    setFormState({ ...formState, [key]: value })
  }

  // submit form
  async function nusukCreateOperation(e) {
    e.preventDefault();
    const data = {
      nusuk_group_id: formState.nusuk_group?.id?.toString(),
      rawda_reservation_date: formState.rawda_reservation_date ? moment(formState.rawda_reservation_date).format("Y-MM-DD") : null
    }

    let res = await createStatemenetWithNusuk(data);
    if (res.status === 200 || res.status === 201) {
      history.push(`/${bundle}/operation-view/${res?.data?.data?.reference_number}`)
    }
  }

  //**** effects

  // check compnay has nusuk cred
  useEffect(() => {
    checkCompanyHasNusukCred();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credReload])

  useEffect(() => {
    if (hasNusukCredentials) {
      fetchNusukGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNusukCredentials]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        Nusuk Create Operation
      </ModalHeader>
      <ModalBody>
        {!hasNusukCredentials ?
          <NusukLoginForm setCredReload={setCredReload} />
          :
          <form>
            <div className="row mx-0">

              {/*  Select Safa Visa Group */}
              <div className="col-md-6 col-12">
                <AutoCompleteField
                  label={`${onlineVisa.GroupName}`}
                  placeholder={onlineVisa.GroupName}
                  name="group-name"
                  id="group-name"
                  isSearchable={true}
                  listAuto={passportsGroups || []}
                  setListAuto={setPassportsGroups}
                  getListAuto={(e) => debouncedNusukGroupsSearch(e)}
                  value={formState.nusuk_group?.name || ""}
                  options={passportsGroups || []}
                  onChange={(e) => handleFormInputsChanges({ key: "nusuk_group", value: { name: e } })}
                  onSelectValue={(e) => handleFormInputsChanges({ key: "nusuk_group", value: e })}
                />
              </div>


              {/* Rawda Reservation Date */}
              <div className="col-md-6 col-12">
                <DatePickerField
                  label={"Rawda Reservation Date"}
                  minDate={false}
                  placeholder={"DD/MM/YYYY"}
                  date={formState.rawda_reservation_date}
                  onDateChange={(date) => {
                    handleFormInputsChanges({ key: "rawda_reservation_date", value: date })
                  }}
                  isOutsideRange={(day) => {
                    return !day.isAfter(moment(), "day");
                  }}
                />
              </div>

              <button className="btn bg-nxt py-2 w-100 mt-3"
                onClick={nusukCreateOperation}
                disabled={!formState.rawda_reservation_date || !formState.nusuk_group?.id}
              >
                Create
              </button>
            </div>
          </form>
        }
      </ModalBody>
    </Modal >
  )
}
