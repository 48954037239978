// Translation
import Locale from 'translations';
// Components
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
// Assets
import { ReactComponent as MagicPen } from "assets/images/serviceBuilder/magicpen.svg";
import { languagesLookup } from 'modules/WebBuilder-V2/ChooseTemplate';
// ---------------------------------------------------------------------------------------
function TermsAndConditionsField({
  onChange,
  hasLabel,
  value,
  color,
  errors,
  selectedLang,
  setSelectedLang,
  handleTranslate
}) {

  // *** Hooks
  const { Service_Builder } = Locale

  // ---------- JSX Code --------
  return (
    <>
      <div className='langs-holder'>
        {languagesLookup?.map((lang) =>
          <span
            className={`${selectedLang === lang?.code ? 'active-lang' : 'lang-item'}`}
            onClick={() => {
              setSelectedLang(lang?.code)
            }}
          >
            {lang?.name}
          </span>
        )}
        <button
          className='translate-btn'
          type='button'
          onClick={handleTranslate}
        >
          < MagicPen />
          {Service_Builder?.magicTranslate}
        </button>
      </div>

      <div>
        <TextAreaField
          hasLabel={hasLabel}
          height={'126px'}
          label={Service_Builder?.termsAndConditions}
          placeholder={Service_Builder?.termsAndConditions}
          value={value}
          onChange={(e) => onChange(e)}
          color={color ? "danger" : ""}
          errors={errors}
        />
      </div>
    </>
  )
}

export default TermsAndConditionsField