import PasswordField from 'components/Form/PasswordField/PasswordField';
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from 'helpers/validate';
import React, { useState } from 'react'
import { setNusukCredentials } from 'services/operationStatement';
import Locale from 'translations';

export default function NusukLoginForm({ setCredReload }) {
  // * hooks
  const { login } = Locale;

  // ** states
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    code: ""
  });
  const [errors, setErrors] = useState({});


  // *** functions
  function handleInputsChanges({ key, value }) {
    setFormState({
      ...formState,
      [key]: value
    })
    setErrors({
      ...errors,
      ...validate(
        { name: key, value: value },
        { required: true }
      ),
    });
  }

  function checkFormErrors() {
    let submitError = {};
    Object.keys(formState).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: formState[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formHasErrors = !isFormValid(checkFormErrors());
    if (formHasErrors) return;
    const res = await setNusukCredentials(formState);
    if(res?.status === 200) {
      setCredReload(prev=> !prev);
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className="w-100">
      <div className="row mx-0">
        {/* username */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            type="text"
            label={"Username"}
            placeholder={"Username"}
            name="nusuk_username"
            id="nusuk_username"
            value={formState.username}
            onChange={(e) => handleInputsChanges({ key: "username", value: e.target.value })}
            color={errors?.username?.required ? "danger" : ""}
            errors={errors?.username}
          />
        </div>

        {/* password */}
        <div className="col-md-6 col-12 mt-3">
          <PasswordField
            hasLabel={true}
            label={login.password}
            placeholder={login.passwordPlaceholder}
            name="nusuk_password"
            id="nusuk_password"
            value={formState.password}
            onChange={(e) => handleInputsChanges({ key: "password", value: e.target.value })}
            color={errors?.password?.required || errors?.password?.password ? "danger" : ""}
            errors={errors?.password}
          />
        </div>

        {/* code */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            label={"Code"}
            placeholder={"Code"}
            name="nusuk_code"
            id="nusuk_code"
            value={formState.code}
            onChange={(e) => handleInputsChanges({ key: "code", value: e.target.value })}
            color={errors?.code?.required ? "danger" : ""}
            errors={errors?.code}
          />
        </div>
      </div>
      <button className="btn bg-nxt py-2 w-100 mt-3">
        {login.loginTitle}
      </button>
    </form>
  )
}
