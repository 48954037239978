import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useState } from 'react'
import { SendOperationForm } from './SendOperationForm'
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers'
import { GenerateLinkOrChangeOperatorForm } from './GenerateLinkOrChangeOperatorForm'
import Locale from 'translations'

export default function SendOperationModal({ isOpen, setIsOpen, statementRefNumber, listTripOperations, companiesList }) {
  const {safaVisa} = Locale
  const [isCompanySelectView, setIsCompanySelectView] = useState(true)

  const [data, setData] = useState({
    company: null,
    name: "",
    operation_link: "",
    email: null,
    phone: null,
    country: null,
    phone_code: null,
  });

  async function handleGenerateLink() {
    let domain = window.location.protocol + "//" + window.location.host;
    setData({ ...data, operation_link: `${domain}/public/umrah-operations/${statementRefNumber}` })
    setIsCompanySelectView(false)
  }

  function handleCopyUrl() {
    navigator.clipboard.writeText(data.operation_link).then(() => {
      handleResponesNotification({ alertType: "success", message: "URL Copied" });
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(prev => !prev)}
      centered
      size="xl"
      className="send-operation-modal"
    >
      <ModalHeader toggle={() => setIsOpen(prev => !prev)}>
        {safaVisa.sendToUmrahOperator}
      </ModalHeader>
      <ModalBody>
        {data.operation_link && !isCompanySelectView ? (
          <SendOperationForm
            data={data}
            setData={setData}
            companiesList={companiesList}
            setIsCompanySelectView={setIsCompanySelectView}
            statementRefNumber={statementRefNumber}
            handleCopyUrl={handleCopyUrl}
            setIsOpen={setIsOpen}
            listTripOperations={listTripOperations}
          />
        ) : (
          <GenerateLinkOrChangeOperatorForm
            data={data}
            setData={setData}
            setIsCompanySelectView={setIsCompanySelectView}
            companiesList={companiesList}
            handleGenerateLink={handleGenerateLink}
          />
        )}
      </ModalBody>
    </Modal>
  )
}