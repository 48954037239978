import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import SelectField from 'components/Form/SelectField/SelectField';
import TimePickerField from 'components/Form/TimePickerField/TimePickerField';
import validate from 'helpers/validate';
import { PlusIcon } from "modules/UmrahOperations/shared/Icons";
import { useEffect, useState } from 'react';
import { fetchPorts } from 'services/lookups';
import Locale from 'translations';


const departureInitialObj = {
  data: {
    departureDate: "",
    departureTime: "",
    departureAirport: null,
  },
  errors: {},
};

export default function LandFields({ formState, setFormState }) {
  const { safaVisa, operationStatement, packages } = Locale;
  const { data, errors } = formState.arrival_departure?.land;
  const [ports, setPorts] = useState([])

  function handleArrivalInputChange({ key, value, required }) {
    setFormState((prev) => ({
      ...prev,
      arrival_departure: {
        ...prev.arrival_departure,
        land: {
          ...prev.arrival_departure.land,
          data: {
            ...prev.arrival_departure.land.data,
            [key]: value,
          },
          errors: {
            ...prev.arrival_departure.land.errors,
            ...validate({ name: key, value }, { required }),
          },
        }
      },
    }));
  }

  function handleDepartureInputChange({ key, value, index, required }) {
    setFormState((prev) => {
      return ({
        ...prev,
        arrival_departure: {
          ...prev.arrival_departure,
          land: {
            ...prev.arrival_departure.land,
            data: {
              ...prev.arrival_departure.land.data,
              departures: prev.arrival_departure.land.data.departures.map((dep, i) => {
                if (i === index) {
                  return {
                    ...dep,
                    data: {
                      ...dep.data,
                      [key]: value,
                    },
                    errors: {
                      ...dep.errors,
                      ...validate({ name: key, value }, { required }),
                    },
                  };
                } else {
                  return dep;
                }
              }),
            }
          },
        },
      })
    });
  }

  const addDeparture = () => {
    setFormState((prev) => ({
      ...prev,
      arrival_departure: {
        ...prev.arrival_departure,
        land: {
          ...prev.arrival_departure.land,
          data: {
            ...prev.arrival_departure.land.data,
            departures: [...prev.arrival_departure.land.data.departures, departureInitialObj],
          }
        }
      },
    }));
  };

  // Todo: Move it to the parent component to avoid multiple calls to the API and duplicate code
  const portsFetch = async (transportationTypes) => {
    if (transportationTypes) {
      let Ports = await fetchPorts(966, transportationTypes === "Air" ? 2 : 1);
      setPorts(Ports);
    }
  };

  useEffect(() => {
    portsFetch(formState.arrival_departure.data.transportType?.label)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div>
        <h3 className="title">{operationStatement.ArrivalDetails}</h3>

        <div className="inputs-wrapper">
          {/*  Arrival Date" */}
          <div>
            <DatePickerField
              label={operationStatement.ArrivalDate}
              date={data.arrivalDate}
              onChangeDate={(date) =>
                handleArrivalInputChange({
                  key: "arrivalDate",
                  value: date,
                  required: true,
                })
              }
              errors={errors.arrivalDate}
              color={errors?.arrivalDate?.required ? "danger" : ""}
            />
          </div>

          {/*  Arrival Time" */}
          <div>
            <TimePickerField
              label={packages.arrivalTime}
              placeholder={safaVisa.selectTime}
              date={data.arrivalTime}
              onChangeDate={(date) =>
                handleArrivalInputChange({
                  key: "arrivalTime",
                  value: date,
                  required: true,
                })
              }
              errors={errors.arrivalTime}
              color={errors?.arrivalTime?.required ? "danger" : ""}
            />
          </div>

          {/*  Arrival Airport" */}
          <div>
            <SelectField
              label={operationStatement.ArrivalAirport}
              placeholder={safaVisa.selectAirport}
              options={ports}
              value={data.arrivalAirport?.name}
              onChange={(e) =>
                handleArrivalInputChange({
                  key: "arrivalAirport",
                  value: e,
                  required: true,
                })
              }
              errors={errors.arrivalAirport}
              color={errors?.arrivalAirport?.required ? "danger" : ""}
            />
          </div>
        </div>
      </div>

      <div>
        <h3 className="title">{operationStatement.DepartureDetails}</h3>

        <div className="deprature-list">
          {formState.arrival_departure.land.data.departures.map(
            (departure, index) => (
              <div key={index} className="inputs-wrapper">
                <div>
                  <DatePickerField
                    label={operationStatement.DepartureDate}
                    date={departure.data.departureDate}
                    onChangeDate={(date) =>
                      handleDepartureInputChange({
                        key: "departureDate",
                        value: date,
                        index,
                        required: true,
                      })
                    }
                    errors={departure.errors.departureDate}
                    color={departure.errors?.departureDate?.required ? "danger" : ""}
                  />
                </div>

                {/*  Departure Time" */}
                <div>
                  <TimePickerField
                    label={safaVisa.departureTime}
                    placeholder={safaVisa.selectTime}
                    date={departure.data.departureTime}
                    onChangeDate={(date) =>
                      handleDepartureInputChange({
                        key: "departureTime",
                        value: date,
                        index,
                        required: true,
                      })
                    }
                    errors={departure.errors.departureTime}
                    color={departure.errors?.departureTime?.required ? "danger" : ""}
                  />
                </div>

                {/*  Departure Airport" */}
                <div>
                  <SelectField
                    label={operationStatement.DepartureAirport}
                    placeholder={safaVisa.selectAirport}
                    options={ports}
                    value={departure.data.departureAirport?.name}
                    onChange={(e) =>
                      handleDepartureInputChange({
                        key: "departureAirport",
                        value: e,
                        index,
                        required: true,
                      })
                    }
                    errors={departure.errors.departureAirport}
                    color={departure.errors?.departureAirport?.required ? "danger" : ""}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>


      <button
        type="button"
        onClick={addDeparture}
        className="add-departure-btn"
      >
        <span className="icon">
          <PlusIcon />
        </span>
        {operationStatement.AddDeparture}
      </button>
    </>
  )
}