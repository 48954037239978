import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import { PlusIcon } from 'modules/UmrahOperations/shared/Icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { exportExel } from 'services/tripOperations';
import Locale from 'translations';

export default function Header() {
  const {safaVisa, operationStatement} = Locale

  const history = useHistory()

  const handleExportExel = async () => {
    const res = await exportExel()

    if (res && res.status === 200 && res.data) {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }))
      const link = document.createElement('a')
      link.target = "_blank";
      link.href = url
      link.setAttribute('download', `trip-operation-${moment().format('YYYY-MM-DD')}.xlsx`)
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    }
  }

  return (
    <div className='header'>
      <h3 className='title'>{safaVisa.tripOperations}</h3>
      <div className='d-flex gap-2 flex-wrap'>
        <button className='export-execl-btn' onClick={handleExportExel}>
          <ExportExcelIcon />
          <span>{operationStatement.exportExcel}</span>
        </button>

        <button className='add-btn' onClick={() => history.push("/safa_visa/trip-operations/add")}>
          <PlusIcon color='white' />
          <span>{operationStatement.add}</span>
        </button>
      </div>
    </div>
  )
}