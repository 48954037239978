import { useEffect, useState } from 'react';
// React-Router-Dom
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Locale
import Locale from 'translations';
// Component
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
// Context
import { useSBSState } from 'context/global';
// Assets
import { ReactComponent as AddIcon } from 'assets/images/serviceBuilder/orange_add.svg';
import { ReactComponent as TrashIcon } from 'assets/images/serviceBuilder/trash.svg';
// ------------------------------------------------------------------------------------------------------------

const AttractionPricesTaxes = ({ pricingState, setPricingState, onSelectTaxes}) => {

  // *** State
  const { Service_Builder } = Locale;
  const { allCurrencies } = useSBSState();
  const { status } = useParams()

  const [showTaxes, setShowTaxes] = useState(pricingState?.taxes?.length > 1 || (status === 'edit' && pricingState?.taxes?.length >= 1) || false);

  // *** Function
  const handleServicePriceChange = (key, value) => {
    setPricingState({...pricingState , [key]: value });
  };

    function handleTaxesTypeChange(e, index, taxType) {
    const { value } = e.target
    const selectedItem = pricingState.taxes[index]
    if (value === 'percentage') {
      selectedItem[`value`] = ''
      selectedItem[`${taxType}`] = value
      pricingState.taxes[index] = selectedItem
      setPricingState({ ...pricingState })
    } else {
      selectedItem[`value`] = ''
      selectedItem[`${taxType}`] = value
      pricingState.taxes[index] = selectedItem
      setPricingState({ ...pricingState })
    }
  }

  const handleAddTaxes = () => {
    const newTaxRow = {
      name: '',
      type: '',
      value: '',
    }
    pricingState?.taxes?.push(newTaxRow)
    setPricingState({ ...pricingState })
  }

  const handleRemoveTaxes = (index) => {
    pricingState?.taxes?.splice(index, 1)
    setPricingState({ ...pricingState })
    if (pricingState?.taxes.length === 0) {
      setShowTaxes(false)
    }
  }

  const handleTaxChange = (e, index) => {
    const { value, name } = e.target
    const selectedItem = pricingState.taxes[index]
    // update selectedItem Name
    selectedItem[`${name}`] = value
    pricingState.taxes[index] = selectedItem
    setPricingState({ ...pricingState })
  }


  // -------- JSX Code -------
  return (
    <div className='pricing-holder'>
      <div className='d-flex justify-content-between align-items-center mb-3 gap-1'>
        <h2 className='font-weight-bold '>{Service_Builder?.Price}</h2>
        <div className='flex-grow-1'>
          <SelectField
            hasLabel={false}
            id="currency"
            name="currency"
            value={allCurrencies?.find(curr => curr?.currency_code === pricingState?.currency)?.currency_code || pricingState?.currency}
            options={allCurrencies}
            onChange={(selectedCurrency) => handleServicePriceChange("currency", selectedCurrency?.currency_code)}
          />
        </div>
      </div>

      {/* Attractions Prices Holder */}
      <div className='d-flex price-inputs-holder mb-3 flex-wrap'>
        <div className='col-md-3 col-12 violation-cost-wrapper'>
          <div className='violation-cost-input'>
            <TextField
              hasLabel={false}
              labelInner={status === 'edit' ? true : false}
              label={Service_Builder?.adult_price}
              placeholder={Service_Builder?.adult_price}
              name='adult_price'
              id='adult_price'
              value={pricingState?.adult_price || pricingState?.price?.[0]?.adult_price }
              type='number'
              onChange={(e) => handleServicePriceChange('adult_price', Number(e.target.value))}
            />
          </div>
          <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
            <p className='taxes-currency'>{pricingState?.currency}</p>
          </div>
        </div>

        <div className='col-md-3 col-12 violation-cost-wrapper'>
          <div className='violation-cost-input'>
            <TextField
              hasLabel={false}
              label={Service_Builder?.child_price}
              labelInner={status === 'edit' ? true : false}
              placeholder={Service_Builder?.child_price}
              name='child_price'
              id='child_price'
              value={pricingState?.child_price || pricingState?.price?.[0]?.child_price}
              type='number'
              onChange={(e) => handleServicePriceChange('child_price', Number(e.target.value))}
            />
          </div>
          <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
            <p className='taxes-currency'>{pricingState?.currency}</p>
          </div>
        </div>
      </div>


      {/* {pricingState?.price?.map((item, index) =>


        <div className='d-flex price-inputs-holder mb-3 '>
          <div className='col-md-3 col-12 violation-cost-wrapper'>
            <div className='violation-cost-input'>
              <TextField
                hasLabel={false}
                labelInner={status === 'edit' ? true : false}
                placeholder={Service_Builder?.adult_price}
                name='adult_price'
                id='adult_price'
                value={item?.adult_price}
                type='number'
              // onChange={(e) => handleRoomPriceChange(e, index)}
              />
            </div>
            <div className='violation-cost-select h-100 d-flex justify-content-end align-items-center'>
              <p className='taxes-currency'>{pricingState?.serviceCurrency?.currency_code}</p>
            </div>
          </div>

          <div className='col-md-3 col-12 violation-cost-wrapper'>
            <div className='violation-cost-input'>
              <TextField
                hasLabel={false}
                labelInner={status === 'edit' ? true : false}
                placeholder={Service_Builder?.child_price}
                name='child_price'
                id='child_price'
                value={item?.child_price}
                type='number'
              // onChange={(e) => handleRoomPriceChange(e, index)}
              />
            </div>
            <div className='violation-cost-select h-100 d-flex justify-content-end align-items-center'>
              <p className='taxes-currency'>{pricingState?.serviceCurrency?.currency_code}</p>
            </div>
          </div>
        </div>
      )} */}


      {/* Select Taxes and Add Button */}
      <div className='d-flex mb-3 justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            name={'eheck1'}
            id={'taxes'}
            checked={showTaxes}
            onChange={() => setShowTaxes(!showTaxes)}
          />

          <label
            className='form-check-label font-bold pointer text-caption mx-2 rememberme-label'
            htmlFor={'taxes'}
          >
            {Service_Builder?.taxes}
          </label>
        </div>

        {showTaxes && (
          <button className='add_btn' type='button' onClick={() => handleAddTaxes()}>
            <AddIcon />
            {Service_Builder?.add_taxes}
          </button>
        )}
      </div>

      {/* Taxes Row */}
      {(showTaxes && pricingState?.taxes?.length > 0) &&
        <div className='taxes-holder '>
          {pricingState?.taxes?.map((tax, index) => (
            <div key={index} className='row px-3  '>

              {/* tax name input */}
              <div className='col-12 custom-field  col-lg-3 '>
                <div className="violation-cost-input">
                  <TextField
                    hasLabel={false}
                    placeholder={Service_Builder?.tax_name}
                    name={"name"}
                    id="name"
                    value={pricingState?.taxes[index]?.name}
                    type="text"
                    onChange={(e) => {
                      handleTaxChange(e, index)
                      onSelectTaxes(true)
                    }}
                  />
                </div>
              </div>

              {/* percentage radio input */}
              <div className='mt-3 mt-lg-0 col-12 col-sm-5 col-lg-2'>
                <label htmlFor={`fixed_price_${index}`} className='taxes-type d-flex align-items-center justify-content-center'>
                  <input
                    type="radio"
                    id={`fixed_price_${index}`}
                    name={`type${index}`}
                    value="fixed"
                    checked={tax?.type === 'fixed'}
                    onChange={(e) => {
                      handleTaxesTypeChange(e, index, 'type')
                      onSelectTaxes(true)
                    }}
                  />
                  <p className='taxes-type-value'>  {Service_Builder?.fixed_price}</p>
                </label>
              </div>

              {/* fixed Price input */}
              <div className='mt-3 mt-lg-0 col-12 col-sm-5 col-lg-2 custom-field' >
                <div className="violation-cost-wrapper">
                  <div className="violation-cost-input">
                    <TextField
                      hasLabel={false}
                      disabled={tax?.type !== 'fixed'}
                      placeholder={"1000"}
                      name="value"
                      id="taxes_price"
                      value={pricingState?.taxes?.find((tax, taxIndex) => (tax?.type === 'fixed' && taxIndex === index))?.value}
                      type="number"
                      onChange={(e) => {
                        handleTaxChange(e, index)
                        onSelectTaxes(true)
                      }}

                    />
                  </div>
                  <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                    <p className='taxes-currency'>{pricingState?.currency}</p>
                  </div>
                </div>
              </div>

              {/* percentage radio input */}
              <div className='mt-3 mt-lg-0 col-12 col-sm-5 col-lg-2' >
                <label htmlFor={`percentage_${index}`} className='taxes-type d-flex align-items-center justify-content-center'>
                  <input
                    type="radio"
                    id={`percentage_${index}`}
                    name={`type${index}`}
                    value={"percentage"}
                    checked={tax?.type === 'percentage'}
                    onChange={(e) => {
                      handleTaxesTypeChange(e, index, 'type')
                      onSelectTaxes(true)
                    }}
                  />
                  <p className='taxes-type-value'> {Service_Builder?.percentage}</p>
                </label>
              </div>

              {/* percentage input */}
              <div className='mt-3 mt-lg-0 col-12 col-sm-5 col-lg-2 custom-field' >
                <div className="violation-cost-wrapper">
                  <div className="violation-cost-input">
                    <TextField
                      hasLabel={false}
                      placeholder={'10 %'}
                      name="value"
                      disabled={tax?.type !== 'percentage'}
                      id="taxes_percentage"
                      value={pricingState?.taxes?.find((tax, taxIndex) => ((tax?.type === 'percentage') && (taxIndex === index)))?.value}
                      type="number"
                      onChange={(e) => {
                        handleTaxChange(e, index)
                        onSelectTaxes(true)
                      }}
                    />
                    <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                      <p className='taxes-currency'>%</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* delete btn */}
              <div className='col ' >
                <span
                  className='d-flex justify-content-center pointer-event align-items-center h-100'
                  onClick={() => handleRemoveTaxes(index)}
                >
                  <TrashIcon />
                </span>
              </div>

            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default AttractionPricesTaxes;
