import TextField from 'components/Form/TextField/TextField'
import React, { useState } from 'react'
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/umrah-operations/search-icon.svg";
import Locale from 'translations';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import moment from 'moment';
import SelectField from 'components/Form/SelectField/SelectField';
import { filter } from 'jszip';

export default function DelegationManagementFilter({ filters, statistics, getOfficersList, officersList, handleFiltersChanges, getDelegationsList, resetFilters }) {
  const { marketPlace, payment, productElements, } = Locale;

  const todayDate = moment().format("YYYY-MM-DD")
  const yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD")
  const tomorrowDate = moment().add(1, 'days').format("YYYY-MM-DD")

  const [active, setActive] = useState()

  // console.log(filters)
  // console.log(moment('2024-11-25'))
  return (
    <div>
      <div className="row mx-0 align-items-center">
        {/* search */}
        <div className="col-md-2 col-12">
          <TextField
            hasLabel={false}
            type="text"
            name="delegation-search"
            id="delegation-search"
            placeholder={Locale.movementManagement.search}
            isImage={true}
            image={SeachIcon}
            prependImage={true}
            value={filters.search}
            onChange={(e) => {
              setActive(null)
              handleFiltersChanges({ key: "search", value: e.target.value }
              )
            }}
          />
        </div>
        {/* deleageation date */}
        <div className="col-md-2 col-12">
          <DatePickerField
            hasLabel={false}
            name="delegation-date"
            id="delegation-date"
            placeholder="DD/MM/YYYY"
            date={filters.date}
            onChangeDate={(filterDate) => {
              setActive(null)
              handleFiltersChanges({ key: "date", value: filterDate })
            }}
            isOutsideRange={() => false}
          />
        </div>

        {/* <div className='col-md-3 h-100 quick-date-search'>
        <span
          className={`${active === 'yesterday' && 'active-date'}`}
          onClick={() => {
            getDelegationsList({ date: yesterdayDate })
            setActive('yesterday')
          }}
        >
          {productElements?.yesterday}
        </span>
        <span
          className={`${active === 'today' && 'active-date'}`}
          onClick={() => {
            getDelegationsList({ date: todayDate })
            setActive('today')
          }}
        >
          {productElements?.today}
        </span>
        <span
          className={`${active === 'tomorrow' && 'active-date'}`}
          onClick={() => {
            getDelegationsList({ date: tomorrowDate })
            setActive('tomorrow')
          }}
        >
          {productElements?.tommorow}
        </span>
        
      </div> */}

        <div className="col-md-2 h-100">
          <SelectField
            hasLabel={false}
            id="delegate_name"
            name="delegate_name"
            placeholder={productElements.delegate_name}
            value={filters?.officer?.name}
            onChange={(e) => {
              console.log(e)
              // getOfficersList()
              setActive(null)
              handleFiltersChanges({ key: "officer", value: e })
            }}
            searchable={true}
            options={officersList}
          />
        </div>

        {/* actions buttons */}
        <div className="ml-auto d-flex justify-content-end mb-1">
          <button className="btn bg-nxt search-btn mt-0"
            onClick={() => {
              console.log(filters?.officer?.id)
              let formatedFitlers = {
                // ...filters,
                search: filters?.search,
                date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null,
                officer_id: filters?.officer?.id
              }
              // console.log(formatedFitlers)
              getDelegationsList(formatedFitlers)
            }}
          >
            <SearchIcon />
            <span className='mx-2'>{marketPlace.messages.search}</span>
          </button>

          <button
            className="btn reset-btn mx-2 mt-0"
            onClick={() => {
              resetFilters()
              setActive(null)
            }}
          >
            <ResetFilterIcon />
            <span className='mx-2'>{payment.messages.clear}</span>
          </button>
        </div>
      </div>


      {/* Statistcs Filter and Values */}
      <div className="statistics-wrapper mx-0 align-items-center my-4">

        {/* all */}
        <div
          className={`flex-grow-1 flex-md-grow-0 ${active === 'all' ? 'active-statistic-item' : 'statistic-item'}`}
          onClick={() => {
            setActive('all')
            resetFilters()
            // getDelegationsList({})
          }}
        >
          <p className='statistic-text'>
            {productElements?.all}
          </p>
          <span className='statistic-value'>
            {statistics?.all}
          </span>
        </div>

        {/* yesterday */}
        <div
          className={`flex-grow-1 flex-md-grow-0 ${active === 'yesterday' ? 'active-statistic-item' : 'statistic-item'}`}
          onClick={() => {
            setActive('yesterday')
            handleFiltersChanges({ key: "date", value: moment(yesterdayDate) })
          }}
        >
          <p className='statistic-text'>
            {productElements?.yesterday}
          </p>
          <span className='statistic-value'>
            {statistics?.yesterday}
          </span>
        </div>
        {/* today */}
        <div
          className={`flex-grow-1 flex-md-grow-0 ${active === 'today' ? 'active-statistic-item' : 'statistic-item'}`}
          onClick={() => {
            setActive('today')
            handleFiltersChanges({ key: "date", value: moment(todayDate) })
          }}
        >
          <p className='statistic-text'>
            {productElements?.today}
          </p>
          <span className='statistic-value'>
            {statistics?.today}
          </span>
        </div>

        {/* tomorrow */}
        <div
          className={`flex-grow-1 flex-md-grow-0 ${active === 'tomorrow' ? 'active-statistic-item' : 'statistic-item'}`}
          onClick={() => {
            setActive('tomorrow')
            handleFiltersChanges({ key: "date", value: moment(tomorrowDate) })
          }}
        >
          <p className='statistic-text'>
            {productElements?.tomorrow}
          </p>
          <span className='statistic-value'>
            {statistics?.tomorrow}
          </span>
        </div>

        {/* unExcuted */}
        <div
          className={`flex-grow-1 flex-md-grow-0 ${active === 'unExcuted' ? 'active-statistic-item' : 'statistic-item'}`}
          onClick={() => {
            setActive('unExcuted')
            handleFiltersChanges({ key: "is_executed", value: false })
          }}
        >
          <p className='statistic-text'>
            {productElements?.unExcuted}
          </p>
          <span className='statistic-value'>
            {statistics?.un_executed}
          </span>
        </div>

        {/* excuted */}
        <div
          className={`flex-grow-1 flex-md-grow-0 ${active === 'excuted' ? 'active-statistic-item' : 'statistic-item'}`}
          onClick={() => {
            handleFiltersChanges({ key: "is_executed", value: true })
            setActive('excuted')
          }}
        >
          <p className='statistic-text'>
            {productElements?.excuted}
          </p>
          <span className='statistic-value'>
            {statistics?.executed}
          </span>
        </div>

      </div>

    </div>

  )
}
