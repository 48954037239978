import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from 'helpers/validate';
import { TrashIcon } from '../../icons';
import { PlusIcon } from "modules/UmrahOperations/shared/Icons";
import { useCallback, useEffect, useState } from 'react';
import { fetchTransporters, fetchVehicleTypes } from 'services/lookups';
import { createTransportation } from 'services/tripOperations';
import { useParams } from 'react-router-dom';
import { useSBSState } from 'context/global';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { vehicleInitialData } from '../multiStepFormConfig';
import { TRANSPORTATIONS_REQUEST_LOOKUP } from '../lookups';
import Locale from 'translations';


const AGENT_FIELDS = ["transporterName", "approvalNumber", "operationId"];

const execluedFields = (fields, excludedFields) => {
  const keys = Object.keys(fields);
  const excludedFieldsKeys = excludedFields;
  const excludedFieldsValues = keys.filter((key) => !excludedFieldsKeys.includes(key));
  return excludedFieldsValues.reduce((acc, key) => {
    acc[key] = fields[key];
    return acc;
  }, {});

}

export function TransportationForm({ formId, formState, setFormState }) {
  const {operationStatement} = Locale
  const transportationData = formState.transportation
  const [vehiclesTypesLookups, setVehiclesTypesLookups] = useState([])
  const [transportersList, setTransportersList] = useState([]);

  const { id } = useParams()
  const { locale } = useSBSState()

  const handleTransportationInputChange = ({ key, value, required }) => {
    setFormState((prev) => ({
      ...prev,
      transportation: {
        ...prev.transportation,
        data: {
          ...prev.transportation.data,
          [key]: value,
        },
        errors: {
          ...prev.transportation.errors,
          ...validate({ name: key, value }, { required }),
        },
      },
    }));
  }

  const handleTransportationVehicleInputChange = ({ key, value, index, required }) => {
    setFormState((prev) => ({
      ...prev,
      transportation: {
        ...prev.transportation,
        data: {
          ...prev.transportation.data,
          vehicles: prev.transportation.data.vehicles.map((vehicle, i) => {
            if (i === index) {
              return {
                ...vehicle,
                data: {
                  ...vehicle.data,
                  [key]: value,
                },
                errors: {
                  ...vehicle.errors,
                  ...validate({ name: key, value }, { required }),
                },
              };
            }
            return vehicle;
          }),
        },
      },
    }));
  }

  const addVehicle = () => {
    setFormState((prev) => ({
      ...prev,
      transportation: {
        ...prev.transportation,
        data: {
          ...prev.transportation.data,
          vehicles: [...prev.transportation.data.vehicles, vehicleInitialData],
        },
      },
    }));
  }

  const removeVehicle = (index) => {
    setFormState((prev) => ({
      ...prev,
      transportation: {
        ...prev.transportation,
        data: {
          ...prev.transportation.data,
          vehicles: prev.transportation.data.vehicles.filter((_, i) => i !== index),
        },
      },
    }));
  }

  const generateVehicleYears = useCallback((startYear, endYear) =>
    Array.from({ length: endYear - startYear + 1 }, (_, index) => {
      const year = startYear + index;
      return { id: year, name: year };
    })
    , []);

  const getTransportersList = async (inputValue) => {
    if (inputValue.length > 2) {
      const transportersRes = await fetchTransporters(1, inputValue, "966");
      let result = []
      transportersRes?.forEach(item => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        })
      });
      setTransportersList(result);
    }
  };

  const checkFormErrors = () => {
    let transportationErrors = {};
    let vehiclesErrors = [];

    if (transportationData.data.requestType?.id === "through-external-agent") {
      AGENT_FIELDS.forEach((field) => {
        transportationErrors = {
          ...transportationErrors,
          ...validate({ name: field, value: transportationData.data[field] }, { required: true }),
        };
      });
    } else {
      Object.keys(execluedFields(transportationData.data, AGENT_FIELDS)).forEach((key) => {
        transportationErrors = {
          ...transportationErrors,
          ...validate({ name: key, value: transportationData.data[key] }, { required: true }),
        };
      });
    }

    transportationData.data.vehicles.forEach((vehicle, index) => {
      let vehicleErrors = {};
      Object.keys(vehicle.data).forEach((key) => {
        vehicleErrors = {
          ...vehicleErrors,
          ...validate({ name: key, value: vehicle.data[key] }, { required: true }),
        };
      });
      vehiclesErrors.push(vehicleErrors);
    });

    setFormState((prev) => ({
      ...prev,
      transportation: {
        ...prev.transportation,
        data: {
          ...prev.transportation.data,
          vehicles: prev.transportation.data.vehicles.map((vehicle, i) => {
            return {
              ...vehicle,
              errors: vehiclesErrors[i],
            };
          }),
        },
        errors: transportationErrors,
      },
    }));

    return { transportationErrors, vehiclesErrors };
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const { transportationErrors, vehiclesErrors } = checkFormErrors()
    const isTransportationValid = isFormValid(transportationErrors)
    const isVehiclesValid = vehiclesErrors.every((vehicle) => isFormValid(vehicle))

    if (!isTransportationValid || !isVehiclesValid) return

    const payload = {
      request_type: transportationData?.data?.requestType?.id,
      vehicles: transportationData?.data?.vehicles.map(vehicle => ({
        vehicle_type_id: vehicle?.data?.vehicleType?.id,
        vehicle_model: vehicle?.data?.vehicleModel?.id,
        count: +vehicle?.data?.vehicleCount
      }))
    }


    if (transportationData?.data?.requestType?.id === "through-external-agent") {
      payload.transporter_id = transportationData?.data?.transporterName?.id;
      payload.credential_number = transportationData?.data?.approvalNumber;
      payload.operation_id = transportationData?.data?.operationId;
    }

    await createTransportation(id, payload);
  }

  useEffect(() => {
    async function vehicleTypesLookups() {
      const res = await fetchVehicleTypes();
      setVehiclesTypesLookups(res);
    }
    vehicleTypesLookups();
  }, []);

  return (
    <div className="transportation-form-wrapper">
      <form id={formId} className="transportation-form" onSubmit={handleSubmit}>
        <div className='request-type-select-wrapper'>
          <SelectField
            label={operationStatement.RequestType}
            placeholder={operationStatement.RequestType}
            value={transportationData.data.requestType?.name || ""}
            onChange={(e) => handleTransportationInputChange({ key: "requestType", value: e, required: true })}
            options={TRANSPORTATIONS_REQUEST_LOOKUP}
            errors={transportationData.errors.requestType}
            color={transportationData.errors.requestType?.required ? "danger" : ""}
          />
        </div>

        {
          transportationData.data.requestType?.id === "through-external-agent" && (
            <div className='agent-fields'>
              <div>
                <AutoCompleteField
                  label={operationStatement.TransporterName}
                  placeholder={operationStatement.TransporterName}
                  value={transportationData.data.transporterName?.name || transportationData.data.transporterName || ""}
                  onChange={(e) => handleTransportationInputChange({ key: "transporterName", value: e, required: true })}
                  onSelectValue={(e) => handleTransportationInputChange({ key: "transporterName", value: e, required: true })}
                  isSearchable={true}
                  listAuto={transportersList}
                  setListAuto={setTransportersList}
                  getListAuto={getTransportersList}
                  errors={transportationData.errors.transporterName}
                  color={transportationData.errors.transporterName?.required ? "danger" : ""}

                />
              </div>

              <div>
                <TextField
                  label={operationStatement.approvalNumber}
                  placeholder={operationStatement.approvalNumber}
                  value={transportationData.data.approvalNumber}
                  onChange={(e) => handleTransportationInputChange({ key: "approvalNumber", value: e.target.value, required: true })}
                  errors={transportationData.errors.approvalNumber}
                  color={transportationData.errors.approvalNumber?.required ? "danger" : ""}
                />
              </div>

              <div>
                <TextField
                  label={operationStatement.operationID}
                  placeholder={operationStatement.operationID}
                  value={transportationData.data.operationId}
                  onChange={(e) => handleTransportationInputChange({ key: "operationId", value: e.target.value, required: true })}
                  errors={transportationData.errors.operationId}
                  color={transportationData.errors.operationId?.required ? "danger" : ""}
                />
              </div>
            </div>
          )
        }

        <div className="vehicles-card">
          <h4 className='title'>{operationStatement.vehicles}</h4>

          <div className='content'>
            {
              transportationData.data.vehicles.map((vehicle, index) => (
                <div className='vehicle-row'>
                  <div className="viecle-card">
                    <label>{operationStatement.Vehicletype}</label>
                    <div>
                      <SelectField
                        label={operationStatement.Vehicletype}
                        placeholder={operationStatement.Vehicletype}
                        hasLabel={false}
                        value={vehicle.data.vehicleType?.name || ""}
                        onChange={(e) => handleTransportationVehicleInputChange({ key: "vehicleType", value: e, index, required: true })}
                        options={vehiclesTypesLookups}
                        errors={vehicle.errors.vehicleType}
                        color={vehicle.errors.vehicleType?.required ? "danger" : ""}
                      />
                    </div>
                  </div>

                  <div className="viecle-card">
                    <label>{operationStatement.Model}</label>
                    <div>
                      <SelectField
                        label={operationStatement.Model}
                        placeholder={operationStatement.Model}
                        hasLabel={false}
                        value={vehicle.data.vehicleModel?.name || ""}
                        onChange={(e) => handleTransportationVehicleInputChange({ key: "vehicleModel", value: e, index, required: true })}
                        options={generateVehicleYears(2000, new Date().getFullYear())}
                        errors={vehicle.errors.vehicleModel}
                        color={vehicle.errors.vehicleModel?.required ? "danger" : ""}
                      />
                    </div>
                  </div>

                  <div className="viecle-card">
                    <label>{operationStatement.Count}</label>
                    <div>
                      <TextField
                        label={operationStatement.Count}
                        placeholder={operationStatement.Count}
                        hasLabel={false}
                        value={vehicle.data.vehicleCount || ""}
                        onChange={(e) => handleTransportationVehicleInputChange({ key: "vehicleCount", value: e.target.value, index, required: true })}
                        errors={vehicle.errors.vehicleCount}
                        color={vehicle.errors.vehicleCount?.required ? "danger" : ""}
                      />
                    </div>
                  </div>

                  <div className="remove-vehicle">
                    <button type="button" onClick={() => removeVehicle(index)}><TrashIcon /></button>
                  </div>

                </div>
              ))
            }

            <button className='add-button' type="button" onClick={addVehicle}>
              <span className="icon">
                <PlusIcon />
              </span>
              {operationStatement.AddVehicle}
            </button>
          </div>

        </div>
      </form>
    </div>
  )
}   