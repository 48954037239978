import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import React, { useEffect, useState } from 'react'
import Locale from 'translations';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import OperatingCenterTableRow from '../shared/TableRow';
import EditSupervisorModal from '../Modals/EditSupervisorModal';
import { useSBSState } from 'context/global';
import VehiclesDriverModal from '../Modals/VehiclesDriverModal';
import ChooseOfficialModal from 'modules/UmrahOperations/shared/ChooseOfficialModal';
import useOperationMovementsModals from '../useOperationMovementsModals';
import ExecuteMovementModal from 'modules/UmrahOperations/shared/ExecuteMovementModal';
import EditHallModal from '../Modals/EditHallModal';


export default function OperatingCenterOperations({ filters, movmentsList, setFilters, resetFilters }) {
  // ** hooks
  const { operationStatement, marketPlace, inventory, productElements, productsBuilder, backOffice } = Locale;
  const { allCountries } = useSBSState();

  const {
    toggleSupervisorModal,
    toggleOfficerModal,
    toggleDriversModal,
    supervisorModal,
    officerModal,
    driversModal,
    setSupervisorModal,
    setDriversModal,
    saveOfficerData,
    saveSupervisorData,
    saveDriversData,
    executeModalState,
    toggleExecuteModal,
    handleExecuteMovement,
    setHallModal,
    hallModal,
    toggleHallModal,
    sendHallData
  } = useOperationMovementsModals(resetFilters);

  // ** states
  const [activeStatus, setActiveStatus] = useState("all");


  const subTypesList = [
    { title: operationStatement.All, id: "all" },
    { title: operationStatement.arrival, id: "arrival" },
    { title: operationStatement.transfer, id: "internal_movement" },
    { title: operationStatement.mazar, id: "attraction" },
    { title: operationStatement.departure, id: "departure" },
  ];

  const citiesTypeLookup = [
    { name: operationStatement.allCityType, id: "all" },
    { name: operationStatement.makkahIn, type: "in", id: "makkah_in", city_id: 3, },
    { name: operationStatement.makkahOut, type: "out", id: "makkah_out", city_id: 3 },
    { name: operationStatement.madinahIn, type: "in", id: "madinah_in", city_id: 4 },
    { name: operationStatement.madinahOut, type: "out", id: "madinah_out", city_id: 4 },
  ];

  // handle active sub type
  useEffect(() => {
    setActiveStatus(filters?.type ? filters?.type : "all");
  }, [filters]);




  return (
    <div className='operation-center-list'>
      {/* top header */}
      <div className='align-items-center d-flex flex-grow-1 flex-wrap gap-1 my-4'>
        <ul className='flex-grow-1 types-fitler'>
          {subTypesList?.map(type => {
            return (
              <li key={type?.id} className={activeStatus === type.id ? "active" : ""}
                onClick={() => {
                  setActiveStatus(type?.id);
                  setFilters({
                    ...filters,
                    type: type?.id === "all" ? null : type?.id,
                    list_type: null
                  });
                }}
              >
                {type?.title}
              </li>
            )
          })}
        </ul>
        <div
          className='city-type-input flex-grow-1'
          style={{
            width: "auto !important",
            minWidth: "150px",
          }}
        >
          <SelectField
            hasLabel={false}
            id="cities_type"
            name="cities_type"
            options={citiesTypeLookup}
            value={filters?.city_type?.name}
            onChange={(e) => {
              setFilters({
                ...filters,
                city_type: e,
              })
            }}
          />
        </div>
        <div className="flex-grow-1">
          <TextField
            type="text"
            id="operations-search"
            name="operations-search"
            hasLabel={false}
            placeholder={inventory.messages.search}
            isImage={true}
            image={SeachIcon}
            prependImage={true}
            value={filters?.search}
            onChange={(e) => {
              setFilters({
                ...filters,
                search: e.target.value,
              });
            }}
          />
        </div>
      </div>

      {/* table */}
      <div className='operation-movements-table table-container'>
        <table className="table table-update mb-0">
          <thead>
            <tr>
              <th>{productElements.type}</th>
              <th>{operationStatement.Agent}</th>
              <th>{inventory.messages.arrival}</th>
              <th>{operationStatement.Supervisor}</th>
              <th>{productsBuilder.From}</th>
              <th>{productsBuilder.To}</th>
              <th>{marketPlace.messages.pax}</th>
              <th>{operationStatement.operator}</th>
              {/* <th>{operationStatement.alternative_transportation}</th> */}
              <th>{operationStatement.vehicles}</th>
              <th>{operationStatement.startDateTime}</th>
              <th>{operationStatement.endDateTime}</th>
              <th>{operationStatement.movementOfficial}</th>
              <th>{inventory.messages.Status}</th>
              <th>{inventory.messages.Actions}</th>
            </tr>
          </thead>
          <tbody>
            {movmentsList?.length > 0 ?
              movmentsList?.map((movmentItem, index) => {
                return (
                  <OperatingCenterTableRow
                    key={`${movmentItem?.statement_reference}-${index}`}
                    movmentItem={movmentItem}
                    toggleSupervisorModal={toggleSupervisorModal}
                    toggleOfficerModal={toggleOfficerModal}
                    toggleDriversModal={toggleDriversModal}
                    toggleExecuteModal={toggleExecuteModal}
                    toggleHallModal={toggleHallModal}
                    handleExecuteMovement={handleExecuteMovement}
                  />
                )
              })
              :
              <tr>
                <td colSpan="10">
                  <div className="product-build__product-no-data fullHeight">
                    <i className="icx icx-eye-slash" aria-hidden="true"></i>
                    <h4>{backOffice.noResult}</h4>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>


      {/* modals */}
      {supervisorModal.isOpen &&
        <EditSupervisorModal
          toggle={toggleSupervisorModal}
          supervisorData={supervisorModal}
          countries={allCountries}
          setSupervisorModal={setSupervisorModal}
          saveSupervisorData={saveSupervisorData}
        />
      }

      {hallModal.isOpen &&
        <EditHallModal
          toggle={toggleHallModal}
          hallData={hallModal}
          movmentsList={movmentsList}
          setHallModal={setHallModal}
          sendHallData={sendHallData}
        />
      }

      {officerModal.isOpen &&
        <ChooseOfficialModal
          isOpen={officerModal.isOpen}
          toggle={toggleOfficerModal}
          actionFn={saveOfficerData}
          isEdit={officerModal?.type === "edit"}
          data={officerModal?.data}
        />
      }

      {driversModal.isOpen &&
        <VehiclesDriverModal
          toggle={toggleDriversModal}
          driversData={driversModal}
          countries={allCountries}
          setDriversModal={setDriversModal}
          saveDriversData={saveDriversData}
        />
      }

      {executeModalState.isOpen &&
        <ExecuteMovementModal
          isOpen={executeModalState.isOpen}
          toggle={toggleExecuteModal}
          actionFn={handleExecuteMovement}
        />
      }

    </div>
  )
}
