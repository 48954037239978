import moment from 'moment';
import {
  BusGrayIcon,
  AttractionGrayIcon,
  BusOutlineIcon,
  ClipboardTickIcon,
  HotelCircleGrayIcon,
  HotelGrayIcon,
  LeftArrowIcon,
  PlaneGrayIcon,
  RightArrowIcon,
  ShiledTickIcon,
  DepartPlaneIcon,
  ArrivePlaneIcon
} from '../icons';
import Locale from 'translations';

export default function Header({ tripData }) {
  const {productsBuilder, operationStatement} = Locale

  const closestDepartureDate =
    tripData?.trip?.departures?.length > 0
      ? moment.min(
        tripData?.trip?.departures?.map((departure) =>
          departure?.date ? moment(departure?.date) : null
        )).format("DD-MM-YYYY hh:mm")
      : null;

  const arrivalDate = tripData?.trip?.arrival_date
    ? moment(tripData?.trip?.arrival_date).format("DD-MM-YYYY hh:mm")
    : null

  const arrivalName = `
          ${tripData?.trip?.arrival_port?.code ? tripData?.trip?.arrival_port?.code : "-"}  
          ${tripData?.trip?.arrival_trip_number ? ` - ${tripData?.trip?.arrival_trip_number}` : ""}
        `

  const madinahHotel = tripData?.hotels?.find(hotel => hotel.city_id === 4)
  const makkahHotel = tripData?.hotels?.find(hotel => hotel.city_id === 3)

  const transportationVehiclesCount = tripData?.transportation?.vehicles?.reduce((acc, vehicle) => acc + vehicle?.count, 0)

  return (
    <div className='header'>
      <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td>
                <div className='d-flex gap-2'>
                  <div className='d-flex align-items-center'>
                    <ShiledTickIcon />
                  </div>

                  <div className='d-flex flex-column'>
                    <div className='gray'>{productsBuilder.tripName}</div>
                    <div>{tripData?.trip_name}</div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><HotelGrayIcon /></div>
                      <div className='gray'>{tripData?.trip_level}</div>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><ClipboardTickIcon /></div>
                      <div className='gray'>{tripData?.reference_number}</div>
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div className='d-flex gap-2'>
                  <div className='d-flex align-items-center'>
                    <PlaneGrayIcon />
                  </div>

                  <div className='d-flex flex-column'>
                    <div className='gray'>{operationStatement.Arrival}</div>
                    <div>{arrivalName || "-"}</div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><DepartPlaneIcon /></div>
                      <div className='gray'>{arrivalDate || "-"}</div>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><ArrivePlaneIcon /></div>
                      <div className='gray'>{closestDepartureDate || "-"}</div>
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div className='d-flex gap-2'>
                  <div className='d-flex align-items-center'>
                    <HotelCircleGrayIcon />
                  </div>

                  <div className='d-flex flex-column'>
                    <div className='gray'>{operationStatement.MakkahHotel}</div>
                    <div>{makkahHotel ? makkahHotel?.hotels[0]?.hotel?.name : "-"}</div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><RightArrowIcon /></div>
                      <div className='gray'>{makkahHotel ? moment(makkahHotel?.hotels[0]?.check_in_date).format("YYYY-MM-DD hh:mm") : "-"}</div>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><LeftArrowIcon /></div>
                      <div className='gray'>{makkahHotel ? moment(makkahHotel?.hotels[0]?.check_out_date).format("YYYY-MM-DD hh:mm") : "-"}</div>
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div className='d-flex gap-2'>
                  <div className='d-flex align-items-center'>
                    <HotelCircleGrayIcon />
                  </div>

                  <div className='d-flex flex-column'>
                    <div className='gray'>{operationStatement.MadinahHotel}</div>
                    <div>{madinahHotel ? madinahHotel?.hotels[0]?.hotel?.name : "-"}</div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><RightArrowIcon /></div>
                      <div className='gray'>{madinahHotel ? moment(madinahHotel?.hotels[0]?.check_in_date).format("YYYY-MM-DD hh:mm") : "-"}</div>
                    </div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><LeftArrowIcon /></div>
                      <div className='gray'>{madinahHotel ? moment(madinahHotel?.hotels[0]?.check_out_date).format("YYYY-MM-DD hh:mm") : "-"}</div>
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div className='d-flex gap-2'>
                  <div className='d-flex align-items-center'>
                    <AttractionGrayIcon />
                  </div>

                  <div className='d-flex flex-column'>
                    <div className='gray'>{operationStatement.attractions}</div>
                    <div>({tripData.attractions?.length || 0}) {operationStatement.attractions}</div>
                  </div>
                </div>
              </td>

              <td>
                <div className='d-flex gap-2'>
                  <div className='d-flex align-items-center'>
                    <BusGrayIcon />
                  </div>

                  <div className='d-flex flex-column'>
                    <div className='gray'>{operationStatement.transportation}</div>
                    <div>By <span>{tripData?.transportation?.transporter?.name || "-"}</span></div>
                    <div className='d-flex align-items-center gap-1'>
                      <div><BusOutlineIcon /></div>
                      <div className='gray'>{transportationVehiclesCount || 0}</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}