// Translate
import Locale from "translations";
//  Hooks
import { useParams, useHistory } from "react-router-dom";
import usePreviewMode from "hooks/usePreviewMode";
// Assets
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
// import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import moment, { now } from "moment";
import { useWebBuilderState } from 'context/webBuilder';
// ------------------------------------------------------------------------------------------

export default function PackageCardTheme10(props) {
	const {
		image,
		name,
		name_localized,
		// stars,
		product_uuid,
		hotelName,
		price,
		locationName,
		openEditModal,
		initialName,
		// country,
		currency,
		departure_date_at,
		return_date_at,
	} = props;
	console.log(props);
	
	const { webBuilder } = Locale;
	const { isPreview } = usePreviewMode();
	const { selectedLanguage } = useWebBuilderState();
	const history = useHistory();
	const params = useParams();

	const packageUrl = `/${params.bundle}/web-builder-v2/preview/package/${product_uuid}`;
	// let countryName = country ? (country[selectedLanguage] || country) : (locationName[selectedLanguage] || locationName);

	// let rate = +stars && +stars > 5 ? 5 : +stars;

	// -------- JSX Code --------
	return (
		<div className={`package-card ${!product_uuid ? "empty-service" : ""}`}
			onClick={() => isPreview && history.push(packageUrl)}
		>
			<div className="card-img">
				{!isPreview && !image ? (
					<button className="btn add-service-btn"
						onClick={() => openEditModal(props)}
					>
						<AddIcon />
					</button>
				) : null}

				{image ? (
					<img src={image} alt="our package" />
				) : (
					<img
						src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
						alt="Safa Visa"
					/>
				)}
			</div>

			<div className="card-content-top">
				{/* package name and location */}
				<div className="card-details">

					<h4 className="title" title={name_localized?.[selectedLanguage] || name || initialName}>
						{name_localized?.[selectedLanguage] || name || initialName}
					</h4>
					<p className="location">
						<LocationIcon />
						<span className="mx-2">{locationName?.[selectedLanguage] || locationName?.en || locationName}</span>
					</p>
				</div>
				{/* package price */}
				<div>
					<span>{webBuilder.from}</span>
					<p className="price">
						<span className="amount">{price}</span> {" "}
						<span className="currency">{currency}</span>
					</p>
				</div>
			</div>
			<div className="card-content-bottom">
				<div className="d-flex align-items-center mb-3">
					<Hotelcon className="me-1" />
					{hotelName ? hotelName : " - "}
				</div>

				<div className="d-flex align-items-center mb-3">
					<CalendarIcon className="me-1" />
					<span>
						{`${moment(departure_date_at || now()).format(
							"DD MMM YYYY"
						)} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
					</span>
				</div>

			</div>
		</div>
	);
}
