import { useCallback } from "react";
import { useSBSState } from "context/global";
import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import { createTripOperationDetails, updateTrip } from "services/tripOperations";
import validate, { isFormValid } from "helpers/validate";
import TextField from "components/Form/TextField/TextField";
import { useParams, useHistory } from 'react-router-dom';
import Locale from 'translations';

const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

const REQUIRED_FIELDS = ["tripName", "tripLevel"];

export default function DetailsForm({ formId, formState, setFormState }) {
	const {safaVisa, CRM, operationStatement} = Locale
	const { allCountries } = useSBSState();
	const { data, errors } = formState.details;
	const { id } = useParams();
	const history = useHistory();


	const checkErrors = useCallback(() => {
		let _errors = {};
		Object.keys(data).forEach((key) => {
			if (REQUIRED_FIELDS.includes(key)) {
				_errors = {
					..._errors,
					...validate({ name: key, value: data[key] }, { required: true }),
				};
			}
		});

		setFormState((prev) => ({
			...prev,
			details: {
				...prev.details,
				errors: _errors,
			},
		}));

		return _errors;
	}, [data, setFormState]);

	const handleInputChange = (key, value) => {
		setFormState((prev) => ({
			...prev,
			details: {
				...prev.details,
				data: {
					...prev.details.data,
					[key]: value,
				},
				errors: {
					...prev.details.errors,
					...validate({ name: key, value }, { required: true }),
				},
			},
		}));
	};

	const flag = data.supervisorPhone?.flag;

	const handleSubmit = async (e) => {
		e.preventDefault();

		const fromErrors = checkErrors();
		if (!isFormValid(fromErrors)) return;

		const payload = {
			trip_name: data.tripName,
			trip_level: data.tripLevel,
			supervisor_name: data.supervisor,
			supervisor_number: data.supervisorPhoneNumber,
			supervisor_phone_country_id: data.supervisorPhone?.id?.toString(),
		}
		
		let res

		if (id) {
			res = await updateTrip(id, payload);
		} else {
			res = await createTripOperationDetails(payload);
			history.push(`/safa_visa/trip-operations/edit/${res?.data?.reference_number}`);
		}

	};

	return (
		<div className="details-form-wrapper">
			<form id={formId} className="details-form" onSubmit={handleSubmit}>
				<div>
					<TextField
						label={CRM.tripName}
						placeholder={operationStatement.TypeFileNo}
						value={data.tripName}
						onChange={(e) => handleInputChange("tripName", e.target.value)}
						errors={errors.tripName}
						color={errors?.tripName?.required ? "danger" : ""}
					/>
				</div>

				<div>
					<SelectField
						label={safaVisa.tripLevel}
						placeholder={safaVisa.selectLevel}
						value={data.tripLevel || ""}
						onChange={(e) => handleInputChange("tripLevel", e.name)}
						options={[
							{ name: "1", id: "1" },
							{ name: "2", id: "2" },
							{ name: "3", id: "3" },
							{ name: "4", id: "4" },
							{ name: "5", id: "5" },
						]}
						errors={errors.tripLevel}
						color={errors?.tripLevel?.required ? "danger" : ""}
					/>
				</div>

				<div>
					<TextField
						label={operationStatement.Supervisor}
						placeholder={safaVisa.selectLevel}
						value={data.supervisor}
						onChange={(e) => handleInputChange("supervisor", e.target.value)}
					/>
				</div>

				<div className="phone">
					<label className="m-0">{safaVisa.supervisorPhoneNumber}</label>
					<div className="phone_field_group">
						<div className={`${flag ? "flag" : "no-flag"}`}>
							<SelectField
								label={safaVisa.supervisorPhoneNumber}
								hasLabel={false}
								id="phone_code"
								name="phone_code"
								onChange={(e) => handleInputChange("supervisorPhone", e)}
								value={
									flag ? (
										<img
											src={`${fetchFlag}/${flag}`}
											width="35px"
											alt={"phone_code"}
										/>
									) : (
										data.supervisorPhone?.phone_code || data.supervisorPhone
									)
								}
								options={allCountries}
							/>
						</div>

						<div className="phone_number">
							<NumberField
								type={"number"}
								label={"PhoneNumber"}
								hasLabel={false}
								id="phone"
								name="phone"
								value={data.supervisorPhoneNumber}
								onChange={(e) =>
									handleInputChange("supervisorPhoneNumber", e.target.value)
								}
								removeArrow={true}
								placeholder={"000 0000 000"}
								min={3}
								extraTextPosition="prepend"
								extraText={
									data.supervisorPhone?.phone_code ||
									data.supervisorPhone?.label ||
									data.supervisorPhone?.value
								}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}